import styles from "./NewInstruction.module.css";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { Switch, Typography } from "antd";
import { StartCamera, startRecording } from "../../Services/RecordingCam";
import Webcam from "react-webcam";
import { DownloadOutlined } from "@ant-design/icons";
const { Text } = Typography;

const NewInstruction = ({
  instructions,
  checking,
  Settings,
  setmockstart,
  mockTitle,
  mockData,
  duration,
  setIntroduction,
  Extra,
}) => {
  const [cameraallowed, setCameraallowed] = useState(false);
  const [screenallowed, setScreenallowed] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { user, clientdetails } = useSelector((state) => state.Data);
  const sectionRefs = [useRef(null), useRef(null), useRef(null)];
  const scrollToSection = (index) => {
    if (sectionRefs[index] && sectionRefs[index].current) {
      sectionRefs[index].current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const videoConstraints = {
    width: 1300,
    height: 800,
    facingMode: "user",
  };
  const AllowCamera = () => {
    setLoading(true);
    StartCamera()
      .then((res) => {
        if (res) {
          setLoading(false);
          setCameraallowed(true);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const AllowScreen = () => {
    startRecording()
      .then((res) => {
        if (res) {
          setScreenallowed(true);
        } else {
          let confirm = window.confirm(
            "Please Select Full Screen or Entire Screen"
          );
          if (confirm) {
            window.location.reload(true);
          }
        }
      })
      .catch((err) => {
        let confirm = window.confirm(
          "Please Select Full Screen or Entire Screen"
        );
        if (confirm) {
          // AllowScreen();
          window.location.reload(true);
        }
      });
  };

  // Function to format time (convert seconds to HH:MM:SS format)
  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  }

  function downloadFile(path, type) {
    if (type === "all") {
      Extra.forEach((item) => {
        const link = document.createElement("a");
        link.href = item.filepath;
        link.setAttribute("download", true);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } else {
      const link = document.createElement("a");
      link.href = path;
      link.setAttribute("download", true);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <div className={styles.main}>
      <div className={styles.leftSide}>
        <section>
          <div
            onClick={() => {
              navigate("/user");
            }}
          >
            <img src={clientdetails?.logo} alt="logo" />
          </div>
        </section>
        <main>
          <div>
            <label>Hey! {user?.FullName}</label>
          </div>
          <div>
            <p>
              Welcome to <span>{mockTitle} Test </span>
            </p>
          </div>
          <section>
            <div>
              <label>Test Duration</label>
              <p>{formatTime(Number(duration) * 60)} </p>
            </div>
            <div>
              <label>Total Question</label>
              <p>{mockData?.length} Questions</p>
            </div>
          </section>
        </main>
      </div>
      <div className={styles.rightSide}>
        <section className={styles.fristSection} ref={sectionRefs[0]}>
          <div>
            <h3>Please note:</h3>
            <label>
              1. Be in a quiet and controlled space, suitable seating
              arrangements, and adequate lighting.
            </label>
            <label>2. Ensure a good Internet/Wifi Connection.</label>
            <label>3. The test is in English.</label>
            <label>
              4. Do not refresh the page while attempting this test.
            </label>
            <label>
              5. There is no option to go back to the previous questions.
            </label>
            <br />
            {Extra.length > 0 && (
              <div>
                <label>
                  <b>Attachments</b>
                </label>
                <div className="d-flex justify-content-between flex-wrap">
                  {" "}
                  <label>
                    Download the attachments that you may require for the test.
                  </label>
                </div>
                <br />
                {Extra.map((file, ind) => {
                  return (
                    <div key={ind} className="d-flex justify-content-between">
                      <Text style={{ width: "250px" }} ellipsis={true}>
                        {file.name} dfdfd fd fd ff d df df ffd dfdfdf{" "}
                      </Text>
                      <DownloadOutlined
                        onClick={() => downloadFile(file.filepath)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {Settings?.capture_intro || Settings?.capture_user ? (
              <button
                onClick={() => {
                  scrollToSection(1);
                  checking(false);
                }}
              >
                Next
              </button>
            ) : (
              <button
                className={styles.next_btn}
                onClick={() => {
                  instructions(false);
                  if (
                    (Settings?.capture_user ?? true) &&
                    (Settings?.capture_intro ?? true)
                  ) {
                    setIntroduction(true);
                  } else {
                    setmockstart(true);
                  }
                  checking(false);
                }}
              >
                Start Test
              </button>
            )}
          </div>
        </section>
        {(Settings?.capture_intro || Settings?.capture_user) && (
          <section className={styles.secondSection} ref={sectionRefs[1]}>
            <div>
              <h2 className={styles.ins}>Device Access</h2>
              <label className={styles.instruct}>
                Please Check and allow the camera and other access for the TEST
              </label>
              <div className={styles.accesscontrol}>
                {(Settings?.capture_user ?? true) && (
                  <div className={styles.acceeslblswitch}>
                    <label className={styles.accesslabel}>
                      Camera & Microphone
                    </label>
                    <Switch
                      style={{ marginLeft: "5px" }}
                      onChange={(e) => (e ? AllowCamera() : null)}
                      disabled={cameraallowed}
                      checked={cameraallowed}
                      loading={loading}
                    />
                  </div>
                )}

                {(Settings?.capture_screen ?? true) && (
                  <div
                    className={`${styles.acceeslblswitch} ${styles.windowwidth}`}
                  >
                    <label className={styles.accesslabel}>
                      Screen Recording
                    </label>
                    <Switch
                      style={{ marginLeft: "5px" }}
                      onChange={(e) => (e ? AllowScreen() : null)}
                      disabled={screenallowed}
                      checked={screenallowed}
                    />
                  </div>
                )}
              </div>
              {(((Settings?.capture_screen ?? true) &&
                (Settings?.capture_user ?? true) &&
                cameraallowed &&
                screenallowed) ||
                ((Settings?.capture_user ?? true) &&
                  !(Settings?.capture_screen ?? false) &&
                  cameraallowed) ||
                ((Settings?.capture_screen ?? true) &&
                  !(Settings?.capture_user ?? false) &&
                  screenallowed)) && (
                <div className={styles.container}>
                  {cameraallowed && (
                    <div className={styles.camera}>
                      <Webcam
                        audio={false}
                        height={"100%"}
                        width={"100%"}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        className={styles.cam}
                        mirrored={true}
                      />
                    </div>
                  )}

                  <div className={styles.buttons}>
                    <button
                      className={styles.back_Btn}
                      onClick={() => scrollToSection(0)}
                    >
                      Back
                    </button>

                    <button
                      className={styles.next_btn}
                      onClick={() => {
                        instructions(false);
                        if (
                          (Settings?.capture_user ?? true) &&
                          (Settings?.capture_intro ?? true)
                        ) {
                          setIntroduction(true);
                        } else {
                          setmockstart(true);
                        }
                        checking(false);
                      }}
                    >
                      Start Test
                    </button>
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default NewInstruction;
