import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styles from "./createmock.module.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import omrSheetUrl from "./pdf.pdf";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import { v4 as uuidv4 } from "uuid";
import "jspdf-autotable";
import ReactQuill from "react-quill";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import "react-quill/dist/quill.snow.css";
import {
  Select,
  Input,
  Spin,
  Button,
  InputNumber,
  Radio,
  Switch,
  message,
  Tooltip,
  Form,
  Row,
  Col,
  Checkbox,
  Modal,
  theme,
  Steps,
  notification,
  Table,
  Tag,
} from "antd";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getrequest, postrequest } from "../../Middleware/managerequest";
import DashboardSlider from "../../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../../Navigator_Footer/ProfileSlider";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";
const { TextArea } = Input;
const minutesMapping = {
  Coding: {
    Easy: 600,
    Medium: 900,
    Hard: 1200,
  },
  Question: {
    Easy: 60,
    Medium: 80,
    Hard: 120,
  },
  Mcq: {
    Easy: 50,
    Medium: 70,
    Hard: 110,
  },
  Passage: {
    Easy: 100,
    Medium: 170,
    Hard: 210,
  },
};
const preDeinfiInst = `<html>
<head></head>
<body>
  <p>
    1. Immediately after the commencement of the examination, you should check that this test booklet does not have any unprinted or torn or missing pages or items. if so, get it replaced by a complete test booklet.
  </p>
  <p>
    2. Please note that it is the candidate's responsibility to encode and fill in the Roll Number and Test Booklet Series A, B, C, or D carefully and without any omission or discrepancy at the appropriate places in the OMR Answer Sheet. Any omission/discrepancy will render the Answer Sheet liable for rejection.
  </p>
  <p>
    3. You have to enter your Roll Number on the Test Booklet in the box provided alongside. DO NOT write anything else on the Test Booklet.
  </p>
  <p>
    4. This Test Booklet contains 100 items (questions). Each item is printed both in Hindi and English. Each item comprises four responses (answers). You will select the response which you want to mark on the Answer Sheet.
  </p>
</body>
</html>`;
const preDeinfiInstTech = `<html>
<head></head>
<body>
  <p>
    1. Be in a quiet and controlled space, suitable seating arrangements, and adequate lighting.
  </p>
  <p>
  2. Ensure a good Internet/Wifi connection.
  </p>
  <p>
   3. The test is in English.
  </p>
  <p>
  4. Do not refresh the page while attempting this test.
  </p>
  <p>
 5. There is no option to go back to the previous questions.
  </p>
</body>
</html>`;

const typesOFTest = [
  "One Time Test",
  "OMR based MCQ",
  "Offline Written",
  "Scholarship",
  "Online based MCQ",
];
const { Option } = Select;

const CreateMock = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [TestName, setTestName] = useState(searchParams.get("add"));
  const [instruction, setInstruction] = useState("");
  const [openDwa, setOpenDwa] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [passageSubQuestionCount, setPassageSubQuestionCount] = useState(0);
  const [mockcat, setMockcat] = useState(
    searchParams.get("add") ?? "One Time Test"
  );
  const [editingScholarKey, setEditingScholarKey] = useState("");
  const editScholar = (key) => {
    setEditingScholarKey(key);
  };
  const isEditingScholar = (record) => record.key === editingScholarKey;
  const [ScholarShipData, setScholarShipData] = useState([]);
  const saveScholarShipData = (key) => {
    const newData = [...ScholarShipData];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item });
      setScholarShipData(newData);
      setEditingScholarKey("");
    }
  };
  const handleAddScholar = () => {
    setScholarShipData([
      ...ScholarShipData,
      {
        key: ScholarShipData.length,
        offer: "10",
        score: "90",
      },
    ]);
  };
  const [editorHtml, setEditorHtml] = useState(preDeinfiInst);
  useEffect(() => {
    setEditorHtml(
      mockcat !== "One Time Test" ? preDeinfiInstTech : preDeinfiInst
    );
  }, [mockcat]);
  const dataCmock = useSelector((state) => state.Data);
  const { user } = dataCmock;
  const data = useSelector((state) => state.Data);
  const { clientdetails } = data;

  const [Dates, setDates] = useState({ startdate: "", enddate: "" });
  const [questionbank, setquestionbank] = useState({
    global: false,
    personal: true,
  });

  const handleEditorChange = (html) => {
    setEditorHtml(html);
    setInstruction({ ...instruction, instruction: html });
  };
  // Selected Topics and Tags data
  const [Selected, setSelected] = useState({ Topics: [], Tags: [] });
  const [TopicswiseTags, setTopicwiseTags] = useState();
  const [testSetCount, setTestSetCount] = useState(0);
  const [examPaperQuestionSet, setExamPaperQuestionSet] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Editloading, setEditLoading] = useState(location.state ? true : false);
  const [totaltype, setTotalType] = useState({
    mcq: 0,
    coding: 0,
    simple: 0,
    Passage: 0,
    SubQuestions: 0,
  });
  const [countfinalquestion, setCountfinalquestion] = useState({
    Personal: 0,
    Global: 0,
    question: [],
  });
  const [QuestionToShow, setQuestionToShow] = useState([]);

  // Editor Modules
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  // Editor Formats
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const editorRef = useRef(null);
  // Data after filter Tags
  const [globalfilter, setGlobalFilter] = useState([]);
  const [personalfilter, setPersonalFilter] = useState([]);
  // Original Topics and Tags data
  const [Selection, setSelection] = useState({ Topics: [], Tags: [] });
  const [Typeselection, setTypeSelection] = useState({
    Question: false,
    Coding: false,
    Mcq: false,
    Passage: false,
  });
  useEffect(() => {
    if (countfinalquestion?.question?.length > 0 && Typeselection) {
      let filteredQuestions = countfinalquestion.question.filter(
        (ele) => Typeselection[ele?.type]
      );
      deStucherData(filteredQuestions);
    }
  }, [Typeselection, countfinalquestion]);
  const deStucherData = (data) => {
    let filteredquestion = [];
    let arr = data?.map((item) => {
      let obj = {
        key: item.qid,
        question: item?.qstn.question,
        type: item?.type === "Question" ? "Theory" : item?.type,
        maintopics: item?.maintopics?.map((item) => item),
        tags: item?.concept?.map((val) => val),
        level: item?.level,
        title: item.title ?? undefined,
        item,
      };
      let filter = {
        text: item?.qstn.question,
        value: item?.qstn.question,
      };
      filteredquestion.push(filter);
      return obj;
    });

    setQuestionToShow(arr);
  };

  const [edit, setEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [Mockid, setMockId] = useState(uuidv4());

  // Settings
  const [settings, setSettings] = useState({
    copypaste: true,
    autofeedback: false,
    TestTimer: false,
    visible: false,
  });
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();

  const [warning, setWarning] = useState(false);
  const [Capture, setIsCapturing] = useState({ screen: true, user: true });
  const [closeontab, setCloseOnTab] = useState({ close: true, limit: 3 });
  const [Practicemode, setPracticemode] = useState(false);
  const [testSet, setTestSet] = useState(false);
  const [Introduction, setIntroduction] = useState(false);
  const [finaldata, setFinalData] = useState([]);
  const [totalselectedtheory, setTotalSelectedTheory] = useState(0);

  const [Questionandduration, setQuestionandduration] = useState({
    duration: 0,
    totalquestion: 0,
  });

  // Original Data
  const [QuestionData, setQuestionData] = useState({
    globaldata: [],
    personaldata: [],
  });
  // Data after filter Topics
  const [QuestionFinaldata, setQuestionFinaldata] = useState({
    globaldata: [],
    personaldata: [],
  });
  // Create a reusable function for InputNumber component
  const renderInputNumber = (
    globalValue,
    itemValue,
    type,
    difficulty,
    data
  ) => {
    const defaultval = (val, bnk) => {
      const filteredItem = data.find((item) => item.MainTags === val?.Topic);

      if (filteredItem) {
        return filteredItem[bnk]?.[type]?.[difficulty];
      }
      // Return a default value if the filtered item is not found or doesn't contain the expected structure
      return 0;
    };
    return (
      <>
        {(globalValue?.[type]?.[difficulty] === undefined ||
          globalValue?.[type]?.[difficulty] === 0) &&
        (itemValue?.[type]?.[difficulty] === undefined ||
          itemValue?.[type]?.[difficulty] === 0) ? (
          <span style={{ width: "100px" }}>---</span>
        ) : (
          <>
            <InputNumber
              className={styles.numberinput}
              // addonBefore={"Global"}
              addonAfter={`${globalValue?.[type]?.[difficulty] ?? "0"}`}
              controls={false}
              size="small"
              style={{
                display:
                  globalValue?.[type]?.[difficulty] === undefined ||
                  globalValue?.[type]?.[difficulty] === 0
                    ? "none"
                    : null,
              }}
              max={globalValue?.[type]?.[difficulty]}
              onChange={(e) =>
                handleInputs(
                  e,
                  `${type}_${difficulty}_Global_${globalValue?.Topic}`
                )
              }
              defaultValue={defaultval(globalValue, "Global")}
            />
            <InputNumber
              className={styles.numberinput}
              // addonBefore={"Personal"}
              addonAfter={`${itemValue?.[type]?.[difficulty] ?? "0"}`}
              controls={false}
              size="small"
              style={{
                display:
                  itemValue?.[type]?.[difficulty] === undefined ||
                  itemValue?.[type]?.[difficulty] === 0
                    ? "none"
                    : null,
              }}
              max={itemValue?.[type]?.[difficulty]}
              onChange={(e) =>
                handleInputs(
                  e,
                  `${type}_${difficulty}_Personal_${itemValue?.Topic}`
                )
              }
              defaultValue={defaultval(itemValue, "Personal")}
            />
          </>
        )}
      </>
    );
  };

  const convertDateIntoNormalend = (val) => {
    // Create a Date object from the input string
    const date = new Date(val);

    // Extract the day, month, and year components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Extract the hours and minutes components
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDate;
  };
  //  Edit mock

  const initializeValues = useCallback(() => {
    if (location.state) {
      const {
        MockTitle,
        Test_Name,
        MockData,
        Duration,
        MockStart,
        Mockid,
        NoofQ,
        Testid,
        Extra,
        Settings,
      } = location.state.details;

      let editstartdate = new Date(MockStart),
        editenddate = new Date(
          location.state.details.Mockend !== " " &&
            location.state.details.Mockend
        );
      let editedstartdate =
        editstartdate.getDate() < 10
          ? "0" + editstartdate.getDate()
          : editstartdate.getDate();
      let editedstartmonth =
        editstartdate.getMonth() + 1 < 10
          ? editstartdate.getMonth() + 1
          : editstartdate.getMonth() + 1;
      let finalstart = `${editstartdate.getFullYear()}-${editedstartmonth}-${editedstartdate}  ${editstartdate.getHours()}:${editstartdate.getMinutes()}`;
      let editedenddate =
        editenddate.getDate() < 10
          ? "0" + editenddate.getDate()
          : editenddate.getDate();
      let editedendmonth =
        editenddate.getMonth() + 1 < 10
          ? editenddate.getMonth() + 1
          : editenddate.getMonth() + 1;
      let finalend = `${editenddate.getFullYear()}-${editedendmonth}-${editedenddate} ${editenddate.getHours()}:${editenddate.getMinutes()}`;

      setTestName(MockTitle ?? Test_Name);
      setEditorHtml(Extra?.Instruction?.instruction);
      form.setFieldsValue({ testName: MockTitle });
      setDates({
        startdate: new Date(finalstart),
        enddate: location.state.details.Mockend !== " " && new Date(finalend),
      });
      setQuestionandduration({ duration: Duration, totalquestion: NoofQ });
      setMockcat(location.state.details.type ?? "One Time Test");
      setEdit(true);
      setScholarShipData(Extra?.Scholarship ?? []);
      setquestionbank({
        global: Extra?.isglobal ?? MockData[0].globalcount > 0,
        personal: Extra?.ispersonal ?? MockData[0].personalcount > 0,
      });
      setFinalData(MockData);
      let arr = [],
        Tags = [];
      MockData.map((item) => {
        arr.push(item.MainTags);
        Tags.push(item.Tags);
        return true;
      });

      let newTopics = [...new Set(arr)],
        newTags = [...new Set(Tags)];

      setSelected({
        Topics: Extra?.Topics ?? newTopics,
        Tags: Extra?.Tags ?? newTags,
      });
      setMockId(Mockid ?? Testid);
      setTypeSelection({
        Mcq: true,
        Question: true,
        Coding: true,
        Passage: true,
      });
      setIsCapturing({
        user: Settings?.capture_user ?? true,
        screen: Settings?.capture_screen ?? true,
      });

      setSettings({
        copypaste: Settings?.copypaste ?? true,
        autofeedback: Settings?.autofeedback ?? false,
        TestTimer: Settings?.TestTimer ?? false,
        visible: Settings?.visible ?? false,
      });

      setCloseOnTab({
        close: Settings?.close_on_tab?.close ?? true,
        limit: Settings?.close_on_tab?.limit ?? 3,
      });
      setPracticemode(Settings?.practice_mode ?? false);
      setIntroduction(Settings?.capture_intro ?? false);
      setEditLoading(false);
    } else {
      setEdit(false);
    }
  }, [location.state, form]);
  useEffect(() => {
    const editor = editorRef.current;
    if (!editor || !editor.editor || !editor.editor.root) return;
  }, []);

  useEffect(() => {
    initializeValues();
  }, [initializeValues]);

  // Final Submit

  const FinalSubmit = () => {
    setShow(false);
    let modifiedfinaldate = new Date();
    modifiedfinaldate.setDate(modifiedfinaldate.getDate() + 10);
    let newenddate = Dates.enddate === "" ? modifiedfinaldate : Dates.enddate;
    let obj = {
      title: TestName,
      mockstart: Dates.startdate,
      mockend: newenddate,
      Mockid: Mockid,
      createdby: user.FullName,
      duration: Questionandduration.duration,
      mockdata: finaldata,
      user: [], // selectedusers,
      NoOfQuestions: Questionandduration.totalquestion,
      edit: edit,
      type: mockcat,
      Settings: {
        capture_user: Capture.user,
        capture_screen: Capture.screen,
        close_on_tab: closeontab,
        practice_mode: Practicemode,
        capture_intro: Introduction,
        copypaste: settings?.copypaste,
        autofeedback: settings?.autofeedback,
        TestTimer: settings?.TestTimer,
        visible: settings?.visible,
      },
      Extra: {
        Topics: Selected?.Topics,
        Tags: Selected?.Tags,
        isglobal: questionbank?.global,
        ispersonal: questionbank?.personal,
        TopicsWiseTags: TopicswiseTags,
        Instruction: instruction,
        ...(mockcat === "Offline Written" && {
          QuestionsSet: examPaperQuestionSet,
        }),
        ...(mockcat === "Scholarship" && {
          Scholarship: ScholarShipData,
        }),
      },
    };

    if (
      mockcat !== searchParams.get("add") &&
      location?.state?.testtype !== "OTT"
    ) {
      postrequest(`/createmock/${user?.subdomain}/${Mockid}`, obj)
        .then((res) => {
          if (res.status === 201) {
            navigate("/admin");
          } else if (res.status === 202) {
            toast.warning("Subscription Expired.", { position: "top-center" });
            navigate("/admin");
          } else {
            navigate("/admin");
          }
        })
        .catch((err) =>
          toast.warning("server error", {
            position: "top-right",
            autoClose: 1500,
          })
        );
    } else if (
      location?.state?.testtype === "CATupdate" ||
      searchParams.get("add") !== ""
    ) {
      postrequest(`/createcategory/${user?.subdomain}/${Mockid}`, obj)
        .then((res) => {
          if (res.status === 201 && location?.state?.testtype === "CATupdate") {
            navigate(`/predefinetest?category=${mockcat}`);
          } else if (
            res.status === 202 &&
            location?.state?.testtype === "CATupdate"
          ) {
            toast.warning("Subscription Expired.", { position: "top-center" });
            navigate(`/predefinetest?category=${mockcat}`);
          } else if (location?.state?.testtype === "CATupdate") {
            navigate(`/predefinetest?category=${mockcat}`);
          } else {
            navigate("/admin");
          }
        })
        .catch((err) =>
          toast.warning("server error", {
            position: "top-right",
            autoClose: 1500,
          })
        );
    }
  };

  const convertDateIntoNormal2 = () => {
    // Create a Date object from the current date and time
    const date = new Date();

    // Extract the day, month, and year components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Extract the hours and minutes components
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDate;
  };

  // Check whether which questions need to render
  const filterToMap =
    personalfilter.length > 0 && globalfilter.length > 0
      ? personalfilter // Both arrays have elements, choose personalfilter
      : globalfilter.length > 0
      ? globalfilter // Only globalfilter has elements
      : personalfilter; //

  const [api, contextHolder2] = notification.useNotification();
  const openNotificationWithIcon = (type, val) => {
    api[type]({
      message: "Alert",
      description: val,
    });
  };

  //handleQuestion Bank
  const handleQuestionBank = (e) => {
    setLoading(true);
    const selectedBank = e.target.value;
    const newQuestionBank = {
      global: selectedBank === "global",
      personal: selectedBank === "personal",
    };
    setquestionbank(newQuestionBank);
  };

  //  Select Topics
  //Select Main Topics
  const onchangemaintopics = (e, globalq, personalq, isgloabl, ispersonl) => {
    setSelected({ ...Selected, Topics: e });
    let Globalquestion = globalq || QuestionData.globaldata;
    let Personalquestion = personalq || QuestionData.personaldata;
    let globalflag = isgloabl || questionbank.global;
    let personalflag = ispersonl || questionbank.personal;
    if (!e) return;

    setGlobalFilter([]);
    setPersonalFilter([]);
    const filterQuestions = (questions, flag) => {
      return questions.filter((item) => {
        if (item.maintopics) {
          return e.some((val) => item.maintopics.includes(val));
        }
        return false;
      });
    };

    const extractUniqueConcepts = (questions) => {
      const uniqueConcepts = new Set();
      questions.forEach((question) => {
        question.concept.forEach((concept) => {
          uniqueConcepts.add(concept);
        });
      });
      return Array.from(uniqueConcepts);
    };

    setQuestionFinaldata({ personaldata: [], globaldata: [] });

    let personalsubtopics = [];
    let globalsubtopics = [];
    let personal = [],
      global = [];
    if (personalflag) {
      personal = filterQuestions(Personalquestion, personalflag);
      personalsubtopics = extractUniqueConcepts(personal);
      setQuestionFinaldata((data) => ({ ...data, personaldata: personal }));
    }

    if (globalflag) {
      global = filterQuestions(Globalquestion, globalflag);
      globalsubtopics = extractUniqueConcepts(global);
      setQuestionFinaldata((data) => ({ ...data, globaldata: global }));
    }

    const mergedSubtopics = [...personalsubtopics, ...globalsubtopics];
    const uniqueSubtopics = Array.from(new Set(mergedSubtopics));
    let filteredArr2 = finaldata.filter((item) => e.includes(item.MainTags));
    setFinalData(filteredArr2);
    const finaldata1 = uniqueSubtopics.map((item) => ({
      label: item,
      value: item,
    }));

    if (Selected?.Tags?.length > 0) {
      filteredtypes(Selected?.Tags, global, personal, e);
    }
    setSelection((tp) => ({ ...tp, Tags: finaldata1 }));
  };

  // Fetch questions
  const fetchquestions = () => {
    setGlobalFilter([]);
    setPersonalFilter([]);
    setLoading(true);
    getrequest(
      `/createmock/${user?.subdomain}/${questionbank.global}/${questionbank.personal}`
    )
      .then((res) => {
        setSelection({ ...Selection, Topics: res.data.MainTopics });
        if (res.data.global && res.data.personal) {
          setQuestionData({
            globaldata: res.data.global,
            personaldata: res.data.personal,
          });
          if (Selected?.Topics?.length > 0) {
            onchangemaintopics(
              Selected?.Topics,
              res.data.global,
              res.data.personal,
              true,
              true
            );
          }
        }
        // If only global question want
        else if (res.data.global) {
          setQuestionData({
            globaldata: res.data.global,
            personaldata: [],
          });
          if (Selected?.Topics?.length > 0 && !res.data.personal) {
            onchangemaintopics(
              Selected?.Topics,
              res.data.global,
              QuestionData.personaldata,
              true,
              questionbank.personal
            );
          }
        }
        // If only personal questions want
        else if (res.data.personal) {
          setQuestionData({
            globaldata: [],
            personaldata: res.data.personal,
          });
          if (Selected?.Topics?.length > 0 && !res.data.global) {
            onchangemaintopics(
              Selected?.Topics,
              QuestionData.globaldata,
              res.data.personal,
              questionbank.global,
              true
            );
          }
        }
        // Disable loading
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error");
        toast.warning("Something went wrong", {
          position: "top-right",
          autoClose: 1500,
        });
      });
  };
  useEffect(() => {
    if (user) {
      fetchquestions();
    }
  }, [questionbank, user]);
  const CheckfinalData = useCallback(
    (val) => {
      const propertiesMapping = {
        Coding: ["Easy", "Medium", "Hard"],
        Question: ["Easy", "Medium", "Hard"],
        Mcq: ["Easy", "Medium", "Hard"],
        Passage: ["Easy", "Medium", "Hard"],
      };
      const applyFilter = (item, filters, type) => {
        filters?.forEach((filter) => {
          if (filter.Topic === item.MainTags) {
            Object.keys(propertiesMapping)?.forEach((category) => {
              propertiesMapping[category]?.forEach((difficulty) => {
                const personalProp = item[type]?.[category]?.[difficulty];
                const personalValProp = filter[category]?.[difficulty];
                if (personalValProp === 0 && personalProp > 0) {
                  item[type][category][difficulty] = 0;
                } else if (personalValProp < personalProp) {
                  item[type][category][difficulty] = personalValProp;
                }
                if (!Typeselection[category]) {
                  item[type][category][difficulty] = 0;
                }
              });
            });
          }
        });
        let qb = { Global: 0, Personal: 0 };
        let types = { Mcq: 0, Question: 0, Coding: 0, Passage: 0 };

        Object.keys(item[type]).forEach((category) => {
          Object.keys(item[type]?.[category]).forEach((difficulty) => {
            qb[type] += item[type]?.[category]?.[difficulty];
          });
        });
        // Calculate total Question from Global and Personal
        ["Global", "Personal"].forEach((category) => {
          ["Question", "Coding", "Mcq", "Passage"].forEach((type) => {
            ["Easy", "Medium", "Hard"].forEach((difficulty) => {
              types[type] += item[category]?.[type]?.[difficulty];
            });
          });
        });
        item.Mcq = types.Mcq;
        item.Coding = types.Coding;
        item.Question = types.Question;
        item.Passage = types.Passage;

        if (type === "Global") {
          item.globalcount = qb.Global;
        } else if (type === "Personal") {
          item.personalcount = qb.Personal;
        }
      };

      let totaltheory = 0;
      val?.forEach((item) => {
        totaltheory += item?.Question;
        if (personalfilter.length > 0 && globalfilter.length === 0) {
          Object.keys(propertiesMapping)?.forEach((category) => {
            propertiesMapping[category]?.forEach((difficulty) => {
              if (item?.Global[category] && item.Global[category][difficulty]) {
                item.Global[category][difficulty] = 0;
              }
            });
          });
        } else if (personalfilter.length === 0 && globalfilter.length > 0) {
          Object.keys(propertiesMapping)?.forEach((category) => {
            propertiesMapping[category]?.forEach((difficulty) => {
              if (
                item.Personal[category] &&
                item.Personal[category][difficulty]
              ) {
                // Update the property dynamically
                item.Personal[category][difficulty] = 0;
              }
            });
          });
        }
        applyFilter(item, personalfilter, "Personal");
        applyFilter(item, globalfilter, "Global");
      });

      if (Typeselection.Question) {
        setTotalSelectedTheory(totaltheory);
      } else {
        setTotalSelectedTheory(0);
      }
      // setFinalData(val);
      const totalTime = calculateTotalTime(val, minutesMapping);
      setQuestionandduration({
        duration: totalTime.totalTime,
        totalquestion: totalTime.totalquestions,
      });
    },
    [personalfilter, globalfilter, Typeselection]
  );

  useEffect(() => {
    CheckfinalData(finaldata);
  }, [Typeselection, Selected?.Tags, questionbank, CheckfinalData, finaldata]);

  //Filtering Diff
  const filterDiff = async (mcq, Coding, Simple, Passage, qbank, TP) => {
    let total = { Mcq: 0, Question: 0, Coding: 0, Passage: 0, SubQuestions: 0 };
    let Topics = TP ?? Selected?.Topics;
    let finalarr = Topics.map((item) => {
      let obj = {
        Topic: item,
        Question: { Easy: 0, Medium: 0, Hard: 0 },
        Mcq: { Easy: 0, Medium: 0, Hard: 0 },
        Coding: { Easy: 0, Medium: 0, Hard: 0 },
        Passage: { Easy: 0, Medium: 0, Hard: 0 },
      };

      mcq.forEach((value) => {
        if (value.maintopics.includes(item)) {
          obj["Mcq"][value.level]++;
          total["Mcq"]++;
        }
      });

      Coding.forEach((value) => {
        if (value.maintopics.includes(item)) {
          obj["Coding"][value.level]++;
          total["Coding"]++;
        }
      });
      Simple.forEach((value) => {
        if (value.maintopics.includes(item)) {
          obj["Question"][value.level]++;
          total["Question"]++;
        }
      });
      Passage.forEach((value) => {
        if (value.maintopics.includes(item)) {
          obj["Passage"][value.level]++;
          total["Passage"]++;
        }
      });
      Passage.forEach((value) => {
        if (value.maintopics.includes(item)) {
          total["SubQuestions"] += value?.qstn?.passageQuestions?.length;
        }
      });

      return obj;
    });
    setTotalType((prevTotalType) => ({
      mcq: prevTotalType.mcq + total.Mcq,
      coding: prevTotalType.coding + total.Coding,
      simple: prevTotalType.simple + total.Question,
      Passage: prevTotalType.simple + total.Passage,
      SubQuestions: prevTotalType.SubQuestions + total.SubQuestions,
    }));

    // Final filtered data
    if (qbank === "personal") {
      setPersonalFilter(finalarr);
    }
    if (qbank === "global") {
      setGlobalFilter(finalarr);
    }
  };

  //Filtering Question Types {Mcq,Coding and Question}
  const filterqstntypes = async (val, type, TP) => {
    let mcq = await val.filter((item) => item.type === "Mcq");
    let Coding = await val.filter((item) => item.type === "Coding");
    let Simple = await val.filter((item) => item.type === "Question");
    let Passage = await val.filter((item) => item.type === "Passage");
    filterDiff(mcq, Coding, Simple, Passage, type, TP);
  };
  //Filtering Question Tags
  const filteredtypes = (val, gbl, psnl, TP) => {
    let global = gbl ?? QuestionFinaldata.globaldata;
    let personal = psnl ?? QuestionFinaldata.personaldata;
    let obj = {};
    setCountfinalquestion({ Global: 0, Personal: 0, questions: [] });
    setTotalType((prevTotalType) => ({
      mcq: 0,
      coding: 0,
      simple: 0,
      Passage: 0,
      SubQuestions: 0,
    }));
    if (questionbank.global) {
      let find = global.filter((item) =>
        val.some((value) => item.concept.includes(value))
      );
      global.forEach((item) => {
        Selected.Topics?.forEach((it) => {
          if (item.maintopics.includes(it)) {
            if (!obj[it]) {
              obj[it] = new Set();
            }
            item.concept.forEach((value) => {
              if (val.includes(value)) {
                obj[it].add(value);
              }
            });
          }
        });
      });

      // Convert Set values to arrays in the object
      Object.keys(obj).forEach((key) => {
        obj[key] = Array.from(obj[key]);
      });

      filterqstntypes(find, "global", TP);
      setCountfinalquestion((prevCount) => ({
        ...prevCount,
        Global: find.length,
        question: find,
      }));
    }

    if (questionbank.personal) {
      let find = personal.filter((item) =>
        val.some((value) => item.concept.includes(value))
      );
      filterqstntypes(find, "personal", TP);
      setCountfinalquestion((prevCount) => ({
        ...prevCount,
        Personal: find.length,
        question: find,
      }));
      personal.forEach((item) => {
        Selected.Topics?.forEach((it) => {
          if (item.maintopics.includes(it)) {
            if (!obj[it]) {
              obj[it] = new Set();
            }
            item.concept.forEach((value) => {
              if (val.includes(value)) {
                obj[it].add(value);
              }
            });
          }
        });
      });

      // Convert Set values to arrays in the object
      Object.keys(obj).forEach((key) => {
        obj[key] = Array.from(obj[key]);
      });
    }
    setTopicwiseTags(obj);
  };

  //Select Tags
  const onchangetopics = (e) => {
    setSelected({ ...Selected, Tags: e });

    if (e) {
      filteredtypes(e);
    }
  };

  // Calculate Total Duration and Question
  const calculateTotalTime = (finaldata, minutesMapping) => {
    let totalTime = 0;
    let totalquestions = 0;

    finaldata.forEach((obj) => {
      ["Mcq", "Coding", "Question", "Passage"].forEach((type) => {
        ["Easy", "Medium", "Hard"].forEach((difficulty) => {
          if (type !== "Passage") {
            totalquestions +=
              obj?.Global[type]?.[difficulty] +
              obj?.Personal[type]?.[difficulty];
            totalTime +=
              (obj?.Global[type]?.[difficulty] +
                obj?.Personal[type]?.[difficulty]) *
              minutesMapping[type][difficulty];
          }
        });
      });
    });
    return { totalTime: Math.ceil(totalTime / 60), totalquestions };
  };

  //HandleInputs
  const handleInputs = (e, name) => {
    let ind = finaldata.findIndex(
      (item) => item.MainTags === name.split("_")[3]
    );
    if (ind !== -1) {
      let obj = finaldata.filter(
        (item) => item.MainTags === name.split("_")[3]
      )[0];

      obj["MainTags"] = name.split("_")[3];
      obj["Tags"] = Selected?.Tags?.map((item) => item);
      let qbank = name.split("_")[2],
        Type = name.split("_")[0],
        Diff = name.split("_")[1];
      if (obj["MainTags"] === name.split("_")[3]) {
        obj[qbank][Type][Diff] = Number(e);
      }

      let qb = { Global: 0, Personal: 0 };
      let type = {
        Mcq: 0,
        Question: 0,
        Coding: 0,
        Passage: 0,
        SubQuestions: 0,
      };
      Object.keys(obj[name.split("_")[2]]).forEach((category) => {
        Object.keys(obj[name.split("_")[2]][category]).forEach((difficulty) => {
          qb[name.split("_")[2]] +=
            obj[name.split("_")[2]][category][difficulty];
        });
      });
      // Calculate total Question from Global and Personal
      for (const category of ["Global", "Personal"]) {
        for (const difficulty of ["Easy", "Medium", "Hard"]) {
          type.Passage += obj[category].Passage[difficulty];
        }
      }
      // Calculate total Question from Global and Personal
      for (const category of ["Global", "Personal"]) {
        for (const difficulty of ["Easy", "Medium", "Hard"]) {
          type.Question += obj[category].Question[difficulty];
        }
      }
      // Calculate total Code from Global and Personal
      for (const category of ["Global", "Personal"]) {
        for (const difficulty of ["Easy", "Medium", "Hard"]) {
          type.Coding += obj[category].Coding[difficulty];
        }
      }
      // Calculate total MCQs from Global and Personal
      for (const category of ["Global", "Personal"]) {
        for (const difficulty of ["Easy", "Medium", "Hard"]) {
          type.Mcq += obj[category].Mcq[difficulty];
        }
      }

      obj["Mcq"] = type.Mcq;
      obj["Coding"] = type.Coding;
      obj["Question"] = type.Question;
      obj["Passage"] = type.Passage;

      obj["globalcount"] = qb.Global;
      obj["personalcount"] = qb.Personal;

      let duplicate = [...finaldata];
      duplicate[ind] = obj;
      setFinalData(duplicate);
    } else {
      let obj = {
        MainTags: "",
        Tags: "",
        globalcount: 0,
        personalcount: 0,
        Mcq: 0,
        Coding: 0,
        Question: 0,
        Passage: 0,
        Global: {
          Mcq: {
            Easy: 0,
            Medium: 0,
            Hard: 0,
          },
          Coding: {
            Easy: 0,
            Medium: 0,
            Hard: 0,
          },
          Question: {
            Easy: 0,
            Medium: 0,
            Hard: 0,
          },
          Passage: {
            Easy: 0,
            Medium: 0,
            Hard: 0,
          },
        },
        Personal: {
          Mcq: {
            Easy: 0,
            Medium: 0,
            Hard: 0,
          },
          Coding: {
            Easy: 0,
            Medium: 0,
            Hard: 0,
          },
          Question: {
            Easy: 0,
            Medium: 0,
            Hard: 0,
          },
          Passage: {
            Easy: 0,
            Medium: 0,
            Hard: 0,
          },
        },
      };
      obj["MainTags"] = name.split("_")[3];
      obj["Tags"] = Selected?.Tags?.map((item) => item);
      let qbank = name.split("_")[2],
        Type = name.split("_")[0],
        Diff = name.split("_")[1];

      obj[qbank][Type][Diff] = Number(e);

      let qb = { Global: 0, Personal: 0 };
      let type = { Mcq: 0, Question: 0, Coding: 0, Passage: 0 };

      Object.keys(obj[name.split("_")[2]]).forEach((category) => {
        Object.keys(obj[name.split("_")[2]][category]).forEach((difficulty) => {
          qb[name.split("_")[2]] +=
            obj[name.split("_")[2]][category][difficulty];
        });
      });

      // Calculate total Question from Global and Personal
      for (const category of ["Global", "Personal"]) {
        for (const difficulty of ["Easy", "Medium", "Hard"]) {
          type.Passage += obj[category].Passage[difficulty];
        }
      }
      for (const category of ["Global", "Personal"]) {
        for (const difficulty of ["Easy", "Medium", "Hard"]) {
          type.Question += obj[category].Question[difficulty];
        }
      }
      // Calculate total Code from Global and Personal
      for (const category of ["Global", "Personal"]) {
        for (const difficulty of ["Easy", "Medium", "Hard"]) {
          type.Coding += obj[category].Coding[difficulty];
        }
      }
      // Calculate total MCQs from Global and Personal
      for (const category of ["Global", "Personal"]) {
        for (const difficulty of ["Easy", "Medium", "Hard"]) {
          type.Mcq += obj[category].Mcq[difficulty];
        }
      }

      obj["Mcq"] = type.Mcq;
      obj["Coding"] = type.Coding;
      obj["Question"] = type.Question;
      obj["Passage"] = type.Passage;

      obj["globalcount"] = qb.Global;
      obj["personalcount"] = qb.Personal;

      setFinalData([...finaldata, obj]);
    }
  };

  // Warning Message
  const Warning = (val) => {
    messageApi.open({
      type: "warning",
      content: val,
    });
  };

  // Form related code

  const { token } = theme.useToken();
  const next = () => {
    if (Questionandduration.totalquestion + passageSubQuestionCount <= 0) {
      message.warning("Please select the question");
    } else if (passageSubQuestionCount > totaltype?.SubQuestions) {
      message.warning(
        `You entered wrong sub questions max is ${totaltype?.SubQuestions}`
      );
    } else if (typesOFTest.includes(mockcat) && Dates?.startdate === "") {
      Warning("Please enter Start Date");
    } else {
      form
        .validateFields()
        .then(() => {
          setCurrent(current + 1);
        })
        .catch((info) => {
          message.warning("Please enter the test name.");
        });
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const contentStyle = {
    lineHeight: "160px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  // Submit Confirmation
  const HandleSubmit = ({ show }) => {
    return (
      <Modal
        open={show}
        closable={false}
        onOk={() => FinalSubmit()}
        onCancel={() => setShow(false)}
        title="Confirm"
        centered
      >
        <label>Are you want to submit?</label>
      </Modal>
    );
  };

  // Before Submit
  const Submit = () => {
    if (totalselectedtheory > 0) {
      setIsCapturing((val) => ({ ...val, user: true }));
    }
    if (TestName === "") {
      Warning("Please enter the Test Name.");
    } else if (typesOFTest.includes(mockcat) && Dates?.startdate === "") {
      Warning("Please enter Start Date");
    } else if (finaldata.length === 0) {
      Warning("Select at least one question.");
    } else {
      setShow(true);
    }
  };
  // Helper function to shuffle an array
  const shuffleArray = (array, finalarr) => {
    const shuffledArray = array.filter(
      (element) => !finalarr.includes(element)
    );
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };
  // Modified handleGlobalQuestion to handle passage subquestion logic
  const handleGlobalQuestion = (item, globalfilter, finalarr, globalBank) => {
    const maintagfilter = globalBank?.questions?.filter((val) => {
      if (val.maintopics) {
        return val.maintopics.includes(item.MainTags);
      } else {
        return false;
      }
    });

    let Tags = [];
    if (item.MainTags) {
      Tags = maintagfilter.filter((val) => {
        if (Array.isArray(item.Tags)) {
          return item.Tags.some((value) => val.concept.includes(value));
        } else {
          return val.concept.includes(item.Tags);
        }
      });
    } else {
      Tags = globalBank?.questions?.filter((val) =>
        val.concept.includes(item.Tags)
      );
    }

    const diff = {
      Mcq: { Easy: [], Medium: [], Hard: [] },
      Coding: { Easy: [], Medium: [], Hard: [] },
      Question: { Easy: [], Medium: [], Hard: [] },
      Passage: { Easy: [], Medium: [], Hard: [] },
    };
    Object.keys(globalfilter).forEach((types) => {
      if (item[types] > 0) {
        const filteredQuestions = Tags.filter((val) => val.type === types);
        const shuffledQuestions = shuffleArray(filteredQuestions, finalarr);
        if (types === "Passage") {
          const selectedPassages = [];
          let subQuestionCount = 0;
          shuffledQuestions.forEach((passage) => {
            const subQuestions = passage.qstn?.passageQuestions?.length;
            if (subQuestionCount + subQuestions <= passageSubQuestionCount) {
              selectedPassages.push(passage);
              subQuestionCount += subQuestions;
            }
            if (subQuestionCount === passageSubQuestionCount) {
              return;
            }
          });
          if (subQuestionCount < +passageSubQuestionCount) {
            message.warning(
              "You do not have enough questions matching your provided count."
            );
            return false;
          }
          diff[types].Easy.push(...selectedPassages);
        } else {
          // Non-passage types
          diff[types].Easy.push(
            ...shuffledQuestions
              .filter((val) => val.level === "Easy")
              .slice(0, globalfilter[types]["Easy"])
          );
          diff[types].Medium.push(
            ...shuffledQuestions
              .filter((val) => val.level === "Medium")
              .slice(0, globalfilter[types]["Medium"])
          );
          diff[types].Hard.push(
            ...shuffledQuestions
              .filter((val) => val.level === "Hard")
              .slice(0, globalfilter[types]["Hard"])
          );
        }
      }
    });
    const filteredArrays = Object.values(diff).reduce((acc, levels) => {
      Object.values(levels).forEach((arr) => {
        if (arr.length > 0) {
          acc.push(...arr);
        }
      });
      return acc;
    }, []);

    return filteredArrays;
  };

  // Modified handlePersonalQuestion with similar passage logic
  const handlePersonalQuestion = (item, personalfilter, finalarr, myBank) => {
    const maintagfilter = myBank?.questions?.filter((val) => {
      if (val.maintopics) {
        return val.maintopics.includes(item.MainTags);
      } else {
        return false;
      }
    });

    let Tags = [];
    if (item.MainTags) {
      Tags = maintagfilter.filter((val) => {
        if (Array.isArray(item.Tags)) {
          return item.Tags.some((value) => val.concept.includes(value));
        } else {
          return val.concept.includes(item.Tags);
        }
      });
    } else {
      Tags = myBank?.questions?.filter((val) =>
        val.concept.includes(item.Tags)
      );
    }

    const diff = {
      Mcq: { Easy: [], Medium: [], Hard: [] },
      Coding: { Easy: [], Medium: [], Hard: [] },
      Question: { Easy: [], Medium: [], Hard: [] },
      Passage: { Easy: [], Medium: [], Hard: [] },
    };

    Object.keys(personalfilter).forEach((types) => {
      if (item[types] > 0) {
        const filteredQuestions = Tags.filter((val) => val.type === types);
        const shuffledQuestions = shuffleArray(filteredQuestions, finalarr);
        if (types === "Passage") {
          const selectedPassages = [];
          let subQuestionCount = 0;
          shuffledQuestions.forEach((passage) => {
            const subQuestions = passage.qstn?.passageQuestions?.length;
            if (subQuestionCount + subQuestions <= passageSubQuestionCount) {
              selectedPassages.push(passage);
              subQuestionCount += subQuestions;
            }
            if (subQuestionCount === passageSubQuestionCount) {
              return;
            }
          });
          let total = 0;

          selectedPassages.forEach((ele) => {
            total += ele?.qstn?.passageQuestions?.length;
          });
          if (total !== passageSubQuestionCount) {
            message.warning("You do not have enough questions");
            return;
          }
          diff[types].Easy.push(...selectedPassages);
        } else {
          // Non-passage types
          diff[types].Easy.push(
            ...shuffledQuestions
              .filter((val) => val.level === "Easy")
              .slice(0, personalfilter[types]["Easy"])
          );
          diff[types].Medium.push(
            ...shuffledQuestions
              .filter((val) => val.level === "Medium")
              .slice(0, personalfilter[types]["Medium"])
          );
          diff[types].Hard.push(
            ...shuffledQuestions
              .filter((val) => val.level === "Hard")
              .slice(0, personalfilter[types]["Hard"])
          );
        }
      }
    });

    const filteredArrays = Object.values(diff).reduce((acc, levels) => {
      Object.values(levels).forEach((arr) => {
        if (arr.length > 0) {
          acc.push(...arr);
        }
      });
      return acc;
    }, []);

    return filteredArrays;
  };
  function htmlToInstructionsArray(htmlContent) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    const paragraphs = tempDiv.getElementsByTagName("p");
    const resultArray = [];
    let temp = "";
    for (let i = 0; i < paragraphs.length; i++) {
      const text = paragraphs[i].innerText.trim();
      if (text) {
        if (/^\d+\./.test(text)) {
          if (temp) {
            resultArray.push(temp);
          }

          temp = text;
        } else {
          temp += " " + text;
        }
      }
    }

    // Push the last instruction into the result array if it exists
    if (temp) {
      resultArray.push(temp);
    }

    return resultArray;
  }
  const convertImageToBase64 = async (url) => {
    try {
      const response = await getrequest(
        `/logo?url=${encodeURIComponent(url)}`,
        {
          responseType: "arraybuffer", // Add this option to handle the binary data
        }
      );

      if (response?.status !== 200) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }
      return response?.data?.base64;
    } catch (error) {
      console.error("Error converting image to Base64:", error);
    }
  };
  // Function to generate the PDF
  const generatePDF = async (data, filename) => {
    const doc = new jsPDF();
    let questionCount = 1;
    let companyURL = "Unoprep";
    let yPosition = 10;
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const leftMargin = 10;
    const rightMargin = pageWidth - 10;
    const bottomMargin = 20;
    const maxLineWidth = rightMargin - leftMargin;
    let pageNum = 1;
    const date1 = new Date(Dates.startdate);
    const date2 = new Date(Dates.enddate);
    const differenceInMs = Math.abs(date2 - date1);
    const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    const differenceInHours = Math.floor(
      (differenceInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const differenceInMinutes = Math.floor(
      (differenceInMs % (1000 * 60 * 60)) / (1000 * 60)
    );
    if (differenceInDays > 0 || differenceInHours > 6) {
      message.warning(
        "Please change the end date, You are not allowed to put this time the time is too much."
      );
      return false;
    }
    // Helper function to create a new page when yPosition exceeds page height
    const checkPageOverflow = () => {
      if (yPosition >= pageHeight - bottomMargin) {
        addFooter(pageNum++); // Add footer before starting a new page
        doc.addPage();
        yPosition = 10; // Reset y position for new page
      }
    };

    const logoBase64 = await convertImageToBase64(clientdetails?.logo);
    const addHeader = async (doc, TestName) => {
      const pageWidth = doc.internal.pageSize.width;
      const leftMargin = 10;
      const rightMargin = pageWidth - 10;

      doc.addImage(logoBase64, "JPEG", 2, 2, 15, 12); // (image, type, x, y, width, height)
      doc.setFontSize(12);
      doc.setFont("bold");
      doc.text(
        "DO NOT OPEN THIS TEST BOOKLET UNTIL YOU ARE TOLD TO DO SO",
        pageWidth / 2,
        20,
        { align: "center" }
      );

      doc.setFontSize(17);
      doc.setFont("bold");
      doc.text(TestName, pageWidth / 2, 30, { align: "center" });

      doc.setFontSize(12);
      doc.text(filename, pageWidth / 2, 40, { align: "center" });

      doc.setFontSize(10);
      doc.text(
        `Time Allowed: ${differenceInHours} hours, ${differenceInMinutes} minutes`,
        leftMargin,
        48
      );
      doc.text("Maximum Marks: 200", rightMargin - 50, 48);
      // Divider line before instructions
      doc.line(leftMargin, 51, rightMargin, 51);

      // Instructions Section
      doc.setFontSize(10);
      doc.setFont("normal");

      doc.setFontSize(19);
      doc.setFont("bold");
      doc.text("Instructions", 10, 62, { align: "left" });
      doc.setFontSize(12);
      let instructionY = 72;
      const maxLineWidth = 190;
      htmlToInstructionsArray(editorHtml).forEach((instruction) => {
        const splitText = doc.splitTextToSize(instruction, maxLineWidth);
        doc.text(splitText, leftMargin, instructionY);
        instructionY += splitText.length * 6;
      });
      yPosition = instructionY;
    };

    addHeader(
      doc,
      TestName,
      convertDateIntoNormalend(Dates.startdate),
      convertDateIntoNormalend(Dates.enddate),
      "12012"
    );

    const addFooter = (pageNum) => {
      const footerYPosition = pageHeight - 10; // Position at the bottom of the page
      doc.setFontSize(10);
      const footerText = `Page ${pageNum} | ${companyURL}`;
      const footerTextWidth = doc.getTextWidth(footerText);

      // Align the footer text to be centered
      doc.text(footerText, (pageWidth - footerTextWidth) / 2, footerYPosition);
      doc.setFontSize(12);
    };

    // Helper function to split text into multiple lines if it exceeds max width
    const splitText = (text) => {
      return doc.splitTextToSize(text, maxLineWidth);
    };

    data.forEach((item) => {
      // Add title for each question type
      doc.setFontSize(16); // Set the font size for the title
      if (item.type === "Passage") {
        const passage = item.qstn.question.replace(/<[^>]+>/g, "");
        yPosition += 7;
        doc.text(`Passage:`, leftMargin, yPosition);
        yPosition += 8;

        // Reset font size for passage content
        doc.setFontSize(12);
        const passageLines = splitText(passage);

        passageLines.forEach((line) => {
          checkPageOverflow();
          doc.text(line, leftMargin, yPosition);
          yPosition += 5.5;
        });
        yPosition += 10;
        doc.setFontSize(15);
        doc.text(`Passage Questions`, leftMargin, yPosition);
        doc.setFontSize(12);
        yPosition += 8;
        doc.setFontSize(12); // Consistent font size for passage questions
        item.qstn.passageQuestions.forEach((q) => {
          const questionText = `${questionCount++}. ${q.description} (Marks: ${
            q.point
          })`;
          const questionLines = splitText(questionText);
          checkPageOverflow();
          doc.text(questionLines, leftMargin, yPosition);
          yPosition += questionLines.length * 8;

          // Add options
          q.options.forEach((option, idx) => {
            const optionText = `${String.fromCharCode(65 + idx)}. ${option}`;
            const optionLines = splitText(optionText);
            doc.text(optionLines, leftMargin + 10, yPosition); // Indent options
            yPosition += optionLines.length * 8;
            checkPageOverflow();
          });

          yPosition += 8; // Add space after each question
        });
      }

      if (item.type === "Mcq") {
        doc.setFontSize(16); // Set the font size for MCQ question title
        const mcqQuestion = item.qstn.question.replace(/<[^>]+>/g, "");
        const mcqQuestionLines = splitText(
          `${questionCount++}. ${mcqQuestion} (Marks: 1)`
        );

        checkPageOverflow();
        doc.text(mcqQuestionLines, leftMargin, yPosition);
        yPosition += mcqQuestionLines.length * 10;

        doc.setFontSize(12); // Set font size for MCQ options

        // Add MCQ options
        ["opt1", "opt2", "opt3", "opt4"].forEach((opt, idx) => {
          checkPageOverflow();
          doc.text(
            `${String.fromCharCode(65 + idx)}. ${item[opt]}`,
            leftMargin + 10,
            yPosition
          );
          yPosition += 8;
        });

        yPosition += 8; // Space after each MCQ question
      }
    });

    addFooter(pageNum); // Add footer to the last page
    doc.save(`${filename}.pdf`);
  };
  const replaceHeader = async (TestName, SetNo, url) => {
    const existingPdfBytes = await fetch(omrSheetUrl).then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const firstPage = pdfDoc.getPages()[0];
    const { width, height } = firstPage.getSize();
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const date1 = new Date(Dates.startdate);
    const date2 = new Date(Dates.enddate);
    const differenceInMs = Math.abs(date2 - date1);
    const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    const differenceInHours = Math.floor(
      (differenceInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const differenceInMinutes = Math.floor(
      (differenceInMs % (1000 * 60 * 60)) / (1000 * 60)
    );
    if (differenceInDays > 0 || differenceInHours > 6) {
      message.warning(
        "Please change the end date, You are not allowed to put this time the time is too much."
      );
      return false;
    }
    const logoBase64 = await convertImageToBase64(clientdetails?.logo);
    const logoImage = await pdfDoc.embedPng(logoBase64); // or use embedJpg if it's a JPG
    const qrCodeDataUrl = await QRCode.toDataURL(url);
    const QRCodeURL = await pdfDoc.embedPng(qrCodeDataUrl);
    const addHeader = (page) => {
      const leftMargin = 10;
      const rightMargin = width - 10;
      page.drawRectangle({
        x: 0,
        y: height - 271,
        width: width,
        height: 271,
        color: rgb(0.9, 0.9, 0.9),
      });

      page.drawImage(logoImage, {
        x: leftMargin, // Adjust X position as needed
        y: height - 50, // Adjust Y position
        width: 45,
        height: 40,
      });
      page.drawText(
        "DO NOT OPEN THIS TEST BOOKLET UNTIL YOU ARE TOLD TO DO SO",
        {
          x: width / 2 - 200,
          y: height - 30,
          size: 12,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        }
      );

      page.drawText(TestName, {
        x: width / 2 - 30,
        y: height - 60,
        size: 17,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });

      page.drawText(`${TestName} OMR Set ${SetNo}`, {
        x: width / 2 - 50,
        y: height - 80,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      page.drawImage(QRCodeURL, {
        x: width - 95, // Adjust X position as needed
        y: height - 220, // Adjust Y position
        width: 85,
        height: 85,
      });

      page.drawText(
        `Time Allowed: ${differenceInHours} hours, ${differenceInMinutes} minutes`,
        {
          x: leftMargin,
          y: height - 120,
          size: 10,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        }
      );

      page.drawText("Maximum Marks: 200", {
        x: rightMargin - 100,
        y: height - 120,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });

      // Divider Line
      page.drawLine({
        start: { x: leftMargin, y: height - 125 },
        end: { x: rightMargin, y: height - 125 },
        thickness: 1,
        color: rgb(0, 0, 0),
      });

      // Instructions Section
      page.drawText("Instructions", {
        x: leftMargin,
        y: height - 145,
        size: 19,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });

      // Instructions from editorHtml
      const instructionsArray = htmlToInstructionsArray(editorHtml);
      let instructionY = height - 165;
      const maxLineWidth = 500;

      instructionsArray.forEach((instruction) => {
        page.drawText(instruction, {
          x: leftMargin,
          y: instructionY,
          size: 12,
          font: helveticaFont,
          color: rgb(0, 0, 0),
          maxWidth: maxLineWidth,
        });
        instructionY -= 20; // Adjust spacing between instructions
      });
    };

    // Add header to the first page
    addHeader(firstPage);

    // Save the updated PDF as bytes
    const pdfBytes = await pdfDoc.save();

    // Trigger download for the updated PDF
    download(pdfBytes, `${TestName} OMR Sheet Set ${SetNo}`, "application/pdf");
  };
  function doDownload(url, fileName) {
    const a = document.createElement("a");
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  const downloadSvgQRCode = (TestName, Index) => {
    const svg = document
      .getElementById(`myqrcode${Index}`)
      ?.querySelector("svg");
    if (!svg) {
      console.error("QR code SVG not found!");
      return;
    }
    const svgData = new XMLSerializer().serializeToString(svg);
    const blob = new Blob([svgData], {
      type: "image/svg+xml;charset=utf-8",
    });
    const url = URL.createObjectURL(blob);
    doDownload(url, `${TestName} Set ${Index}.svg`);
  };
  // Helper function to trigger a download of the updated PDF
  const download = (data, fileName, mimeType) => {
    const blob = new Blob([data], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const generateRandomQuestion = async () => {
    setExamPaperQuestionSet([]);
    if (testSetCount === 0) {
      message.warning("Please enter how many set you want to generate?");
    } else if (testSetCount > 4) {
      message.warning("You can generate max 4 Set at a time.");
    } else {
      try {
        setLoading(true);
        const response = await getrequest(`/questions/${user?.subdomain}`);
        if (response.data) {
          message.success("Set generated successfully");
        }
        const { global, myquestions } = response.data;
        for (let i = 1; i <= testSetCount; i++) {
          let finaldata1 = [];
          finaldata.map((item) => {
            if (item.globalcount > 0) {
              const diff = handleGlobalQuestion(
                item,
                item.Global,
                finaldata1,
                global
              );
              finaldata1.push(...diff);
            }
            if (item.personalcount > 0) {
              const diff = handlePersonalQuestion(
                item,
                item.Personal,
                finaldata1,
                myquestions
              );

              finaldata1.push(...diff);
            }
            return true;
          });
          setLoading(false);
          setExamPaperQuestionSet((pre) => [...pre, finaldata1]);
        }
      } catch (err) {
        setLoading(false);
        message.error("Something went wrong. Please try again later");
        console.log(err);
      }
    }
  };
  const getColumnSearchProps = (dataIndex) => ({
    render: (text, record) => {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: record?.title ?? text }}
          style={{ height: "auto", maxHeight: "200px", overflow: "auto" }}
        />
      );
    },
  });

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      width: 140,
      filters: [
        {
          text: "MCQ",
          value: "Mcq",
        },
        {
          text: "Theory",
          value: "Theory",
        },
        {
          text: "Coding",
          value: "Coding",
        },
        {
          text: "Passage",
          value: "Passage",
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      resposnive: ["md"],
    },

    {
      title: "Question Title",
      dataIndex: "question",
      width: "45%",
      ...getColumnSearchProps("question"),
      resposnive: ["md"],
    },
    {
      title: "Topic",
      dataIndex: "maintopics",
      width: 140,
      resposnive: ["md"],
      filters: Selection.Topics?.map((ele) => ({
        label: ele.label,
        text: ele.label,
      })),

      onFilter: (value, record) => record.maintopics.indexOf(value) === 0,
    },
    {
      title: "Tags",
      dataIndex: "tags",
      width: 160,
      filterMode: "menu",
      filterSearch: true,
      resposnive: ["md"],
      filters: Selection.Tags.map((ele) => ({
        label: ele.label,
        text: ele.label,
      })),

      onFilter: (value, { tags }) => {
        return tags.includes(value);
      },
      render: (value) => {
        return value.map((ite, ind) => {
          return (
            <Tag key={ind} color="gray">
              {ite}
            </Tag>
          );
        });
      },
    },
    {
      title: "Difficulty",
      dataIndex: "level",
      resposnive: ["md"],
      width: 100,
      filters: [
        {
          text: "Easy",
          value: "Easy",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "Hard",
          value: "Hard",
        },
      ],
      onFilter: (value, record) => record.level.indexOf(value) === 0,
    },
  ];
  const stepData = [
    {
      title: "Question Selection",
      content: (
        <>
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item
                label="Test Name"
                name="testName"
                rules={[{ required: true, message: "Please input Test Name!" }]}
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 2 }}
                  value={TestName}
                  onChange={(e) => {
                    setTestName(e.target.value);
                    form.setFieldsValue({ testName: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <>
                    {"Type of Test"}
                    <Tooltip
                      placement="right"
                      title={() => (
                        <div style={{ width: "200px" }}>
                          Select the type of test
                        </div>
                      )}
                    >
                      <InfoCircleOutlined
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          color: "#1890ff",
                        }}
                      />
                    </Tooltip>
                    {/* Corrected placement of "Main Subject" */}
                  </>
                }
                rules={[
                  { required: true, message: "Please select Type of Test!" },
                ]}
              >
                <Select
                  disabled={!typesOFTest.includes(mockcat) ? true : false}
                  placeholder="Select type of test"
                  style={{ width: "100%" }}
                  value={mockcat}
                  onChange={(e) => {
                    setMockcat(e);
                  }}
                >
                  <Option value="OMR based MCQ" disabled>
                    OMR based MCQ (coming soon)
                  </Option>
                  <Option value="One Time Test">Online Test</Option>
                  <Option value="Offline Written" disabled>
                    Offline Written (coming soon)
                  </Option>
                  <Option value="Scholarship">Shcolarship Test</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label={
                  <span>
                    Question Bank
                    <Tooltip title="You need to select the question bank from where you need to pick up the questions. Personal question bank contains the questions created by your organization">
                      <InfoCircleOutlined
                        style={{ marginLeft: 8, color: "#1890ff" }}
                      />
                    </Tooltip>
                  </span>
                }
              >
                <Radio.Group
                  onChange={(e) => handleQuestionBank(e)}
                  value={questionbank.global ? "global" : "personal"}
                >
                  <Radio
                    id="personal"
                    value="personal"
                    checked={questionbank.personal}
                  >
                    Personal
                  </Radio>
                  <Radio
                    id="global"
                    value="global"
                    checked={questionbank.global}
                  >
                    Global
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {loading && (questionbank.global || questionbank.personal) && (
              // Loading
              <Col
                span={9}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <span
                  style={{
                    width: "230px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <label className={styles.myspinner}></label>
                  <label style={{ fontWeight: "600", fontSize: "18px" }}>
                    Fetching Questions...
                  </label>
                </span>
              </Col>
            )}
            {(questionbank.global || questionbank.personal) && !loading && (
              <>
                <Col span={9}>
                  <Form.Item
                    name="maintopics"
                    label={
                      <>
                        {"Main Subject"}
                        {"  "}
                        <Tooltip
                          placement="right"
                          title={() => (
                            <div style={{ width: "200px" }}>
                              Select the main subject that you want to include
                              in your test
                            </div>
                          )}
                        >
                          <InfoCircleOutlined
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                              color: "#1890ff",
                            }}
                          />
                        </Tooltip>
                        {/* Corrected placement of "Main Subject" */}
                      </>
                    }
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select tags"
                      style={{ width: "100%" }}
                      options={Selection.Topics}
                      defaultValue={Selected?.Topics}
                      onChange={(e) =>
                        onchangemaintopics(
                          e,
                          QuestionData.globaldata,
                          QuestionData.personaldata,
                          questionbank.global,
                          questionbank.personal
                        )
                      }
                      showSearch
                    ></Select>
                    <div className="d-flex justify-content-start">
                      {questionbank.global &&
                        QuestionFinaldata.globaldata.length > 0 && (
                          <label>
                            Global : {QuestionFinaldata.globaldata.length}
                          </label>
                        )}
                      {questionbank.personal &&
                        QuestionFinaldata.personaldata.length > 0 && (
                          <label style={{ marginLeft: 10 }}>
                            Personal : {QuestionFinaldata.personaldata.length}
                          </label>
                        )}
                    </div>
                  </Form.Item>
                </Col>
                <Col span={9}>
                  {Selected?.Topics?.length > 0 &&
                    (QuestionFinaldata.personaldata.length > 0 ||
                      QuestionFinaldata.globaldata.length > 0) && (
                      <Form.Item
                        name="tags"
                        label={
                          <>
                            {" Tags"}
                            <Tooltip
                              placement="right"
                              title={() => (
                                <div style={{ width: "200px" }}>
                                  Select the tags mean topic that you want to
                                  include in your test
                                </div>
                              )}
                            >
                              <InfoCircleOutlined
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                  color: "#1890ff",
                                }}
                              />
                            </Tooltip>
                            {/* Corrected placement of "Main Subject" */}
                          </>
                        }
                      >
                        <Select
                          mode="multiple"
                          options={Selection.Tags}
                          value={Selected?.Tags}
                          onChange={(e) => onchangetopics(e)}
                          showSearch
                          style={{ width: "100%" }}
                        ></Select>
                        <div
                          className="d-flex justify-content-between"
                          style={{ width: "230px" }}
                        >
                          {countfinalquestion?.Global > 0 && (
                            <label>Global : {countfinalquestion?.Global}</label>
                          )}
                          <label>
                            Personal : {countfinalquestion?.Personal}
                          </label>
                        </div>
                      </Form.Item>
                    )}
                </Col>
              </>
            )}
          </Row>
          {(countfinalquestion?.Global > 0 ||
            countfinalquestion.Personal > 0) && (
            <Row>
              <Col>
                <Form.Item label="Type of Questions">
                  {totaltype.simple > 0 && mockcat !== "Offline Written" && (
                    <Checkbox
                      onChange={(e) => {
                        setTypeSelection({
                          ...Typeselection,
                          Question: e.target.checked,
                        });
                      }}
                      id="theory"
                      defaultChecked={Typeselection.Question}
                    >
                      Theory/Written
                      <Tooltip
                        placement="right"
                        title={() => (
                          <div style={{ width: "200px" }}>
                            This section requires detailed written responses.
                            Provide comprehensive answers to explain concepts,
                            theories, or ideas in your own words.
                          </div>
                        )}
                      >
                        <InfoCircleOutlined
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            color: "#1890ff",
                          }}
                        />
                      </Tooltip>
                    </Checkbox>
                  )}
                  {totaltype?.Passage > 0 && (
                    <Checkbox
                      onChange={(e) => {
                        setTypeSelection({
                          ...Typeselection,
                          Passage: e.target.checked,
                        });
                      }}
                      id="theory"
                      defaultChecked={Typeselection.Passage}
                    >
                      Passage{" "}
                      <Tooltip
                        placement="right"
                        title={() => (
                          <div style={{ width: "200px" }}>
                            Read the passage carefully before attempting the
                            questions. Focus on the main ideas and supporting
                            details. Pay attention to keywords and phrases that
                            can help in answering the questions accurately.
                          </div>
                        )}
                      >
                        <InfoCircleOutlined
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            color: "#1890ff",
                          }}
                        />
                      </Tooltip>
                    </Checkbox>
                  )}

                  {totaltype.coding > 0 && mockcat !== "Offline Written" && (
                    <Checkbox
                      onChange={(e) => {
                        setTypeSelection({
                          ...Typeselection,
                          Coding: e.target.checked,
                        });
                      }}
                      id="coding"
                      defaultChecked={Typeselection.Coding}
                    >
                      Coding
                      <Tooltip
                        placement="right"
                        title={() => (
                          <div style={{ width: "200px" }}>
                            Provide a clear and concise coding question. Include
                            any specific requirements such as the expected time
                            complexity, input/output format, or coding style
                            guidelines. Ensure the problem is well-defined and
                            solvable within the allocated time.
                          </div>
                        )}
                      >
                        <InfoCircleOutlined
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            color: "#1890ff",
                          }}
                        />
                      </Tooltip>
                    </Checkbox>
                  )}
                  {totaltype.mcq > 0 && (
                    <Checkbox
                      onChange={(e) => {
                        setTypeSelection({
                          ...Typeselection,
                          Mcq: e.target.checked,
                        });
                      }}
                      id="mcq"
                      defaultChecked={Typeselection.Mcq}
                    >
                      MCQ
                      <Tooltip
                        placement="right"
                        title={() => (
                          <div style={{ width: "200px" }}>
                            Create a Multiple Choice Question by providing the
                            question text, options, and the correct answer.
                            Select the correct answer from the options to
                            indicate which choice is correct.
                          </div>
                        )}
                      >
                        <InfoCircleOutlined
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            color: "#1890ff",
                          }}
                        />
                      </Tooltip>
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row>
            <div className={styles.selection}>
              {(countfinalquestion?.Global > 0 ||
                countfinalquestion.Personal > 0) && (
                <main className={styles.HeaderTopics}>
                  <ul id={styles.tableTopic}>
                    <li style={{ width: "160px", textAlign: "left" }}>
                      Subject
                    </li>
                    <li>Easy</li>
                    <li>Medium</li>
                    <li>Hard</li>
                  </ul>
                </main>
              )}

              {(Typeselection?.Mcq ||
                Typeselection?.Coding ||
                Typeselection?.Passage ||
                Typeselection?.Question) &&
                Selected.Tags.length > 0 &&
                Selected.Topics.length > 0 &&
                (questionbank.global || questionbank.personal) &&
                filterToMap.length > 0 &&
                filterToMap.map((item, index) => {
                  return (
                    <Col key={index} span={8}>
                      <div
                        className={styles.selectionmain}
                        key={index}
                        style={{ lineHeight: "4px" }}
                      >
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  minWidth: "210px",
                                  lineHeight: "15px",
                                }}
                              >
                                {item.Topic}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* Loop through types */}
                            {["Question", "Coding", "Mcq", "Passage"].map(
                              (type) => {
                                return (
                                  <React.Fragment key={type}>
                                    {Typeselection?.[type] &&
                                      !(
                                        !item?.[type]?.Easy &&
                                        !item?.[type]?.Medium &&
                                        !item?.[type]?.Hard
                                      ) && (
                                        <tr
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                          }}
                                        >
                                          <td
                                            style={{
                                              width: "210px",
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "flex-start",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span style={{ marginTop: "15px" }}>
                                              {type === "Mcq"
                                                ? "MCQ"
                                                : type === "Question"
                                                ? "Theory"
                                                : type}
                                            </span>

                                            {type === "Passage" && (
                                              <span
                                                style={{ marginTop: "22px" }}
                                              >
                                                Sub question
                                              </span>
                                            )}
                                          </td>

                                          <td
                                            style={{
                                              width: "120px",
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "flex-start",
                                            }}
                                          >
                                            {renderInputNumber(
                                              globalfilter[index],
                                              personalfilter[index],
                                              type,
                                              "Easy",
                                              finaldata
                                            )}
                                            {type === "Passage" && (
                                              <span>
                                                <Input
                                                  addonAfter={`${
                                                    totaltype?.SubQuestions ??
                                                    "0"
                                                  }`}
                                                  type="number"
                                                  controls={false}
                                                  max={totaltype?.SubQuestions}
                                                  min={0}
                                                  size="small"
                                                  onChange={(e) =>
                                                    setPassageSubQuestionCount(
                                                      parseInt(e.target.value)
                                                    )
                                                  }
                                                  value={
                                                    passageSubQuestionCount
                                                  }
                                                />
                                              </span>
                                            )}
                                          </td>

                                          <td
                                            style={{
                                              width: "120px",
                                              display: "flex",
                                            }}
                                          >
                                            {renderInputNumber(
                                              globalfilter[index],
                                              personalfilter[index],
                                              type,
                                              "Medium",
                                              finaldata
                                            )}
                                          </td>
                                          <td
                                            style={{
                                              width: "120px",
                                              display: "flex",
                                            }}
                                          >
                                            {renderInputNumber(
                                              globalfilter[index],
                                              personalfilter[index],
                                              type,
                                              "Hard",
                                              finaldata
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                  </React.Fragment>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  );
                })}
            </div>
            <div className={styles.preview}>
              <h3 style={{ textAlign: "center" }}>Selected Question Preview</h3>
              <div>
                <label>Total Questions</label>
                <label>
                  {+Questionandduration.totalquestion + passageSubQuestionCount}
                </label>
              </div>

              <Tooltip title="View the questions you have selected based on the subject, tags and type of questions.">
                <Button
                  onClick={() =>
                    QuestionToShow.length > 0
                      ? setOpenPreview(true)
                      : message.warning(
                          "Please select the type of questions or select different inputs."
                        )
                  }
                >
                  Preview Question
                </Button>
              </Tooltip>
            </div>
          </Row>
          {typesOFTest.includes(mockcat) && (
            <Row>
              <Col span={12}>
                {typesOFTest.includes(mockcat) && (
                  <>
                    <label
                      style={{
                        textDecoration: !typesOFTest.includes(mockcat)
                          ? "line-through"
                          : null,
                        margin: "10 px",
                      }}
                    >
                      Start Date
                    </label>
                    <input
                      type="datetime-local"
                      value={
                        Dates.startdate &&
                        convertDateIntoNormalend(Dates.startdate)
                      }
                      className={styles.first_row_input}
                      min={convertDateIntoNormal2()}
                      onChange={(e) =>
                        setDates({
                          ...Dates,
                          startdate: new Date(e.target.value),
                        })
                      }
                      disabled={!typesOFTest.includes(mockcat)}
                      style={{
                        backgroundColor: !typesOFTest.includes(mockcat)
                          ? "#dee3e0"
                          : null,
                      }}
                    />
                  </>
                )}
              </Col>
              <Col>
                {/* End Date */}
                {typesOFTest.includes(mockcat) && (
                  <>
                    <label>End Date (optional)</label>{" "}
                    <input
                      type="datetime-local"
                      value={
                        Dates.enddate && convertDateIntoNormalend(Dates.enddate)
                      }
                      onChange={(e) =>
                        setDates({
                          ...Dates,
                          enddate: new Date(e.target.value),
                        })
                      }
                      className={styles.first_row_input}
                      min={convertDateIntoNormalend(Dates.startdate)}
                    />
                  </>
                )}
              </Col>
            </Row>
          )}
        </>
      ),
    },
    {
      title: "Instructions",

      content: (
        <Form.Item name="instructions" label="Instructions">
          <div>
            <ReactQuill
              theme="snow"
              value={editorHtml}
              onChange={handleEditorChange}
              modules={modules}
              formats={formats}
              style={{ height: "250px", borderRadius: "5px" }}
              ref={editorRef}
              placeholder="Update you custom instructions"
            />
          </div>
        </Form.Item>
      ),
    },
    {
      title: "Settings",
      content: (
        <div className={styles.settingandselection}>
          {/* Settings */}
          <div className={styles.settings}>
            <div className={styles.settingBox}>
              {/* Screen Capture */}
              <div className={styles.lefttogglesetting}>
                <label>
                  Capture Screen{" "}
                  <Tooltip
                    placement="right"
                    title={() => (
                      <div style={{ width: "200px" }}>
                        Enabling this option will capture the user's screen
                        activity during the test. This is useful for monitoring
                        the test environment and ensuring test integrity.
                      </div>
                    )}
                  >
                    <InfoCircleOutlined
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        color: "#1890ff",
                      }}
                    />
                  </Tooltip>
                </label>
                <Switch
                  // defaultValue={Capture?.screen}
                  onChange={(val) =>
                    setIsCapturing({ ...Capture, screen: val })
                  }
                  checked={Capture?.screen}
                />
              </div>
              {/* User Capture */}
              <div className={styles.lefttogglesetting}>
                <label>
                  Capture User{" "}
                  <Tooltip
                    placement="right"
                    title={() => (
                      <div style={{ width: "200px" }}>
                        Enabling this option will take a selfie of the user at
                        random intervals during the test to ensure the test is
                        being taken by the registered individual. Make sure to
                        inform users about this policy beforehand.
                      </div>
                    )}
                  >
                    <InfoCircleOutlined
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        color: "#1890ff",
                      }}
                    />
                  </Tooltip>
                </label>
                <Switch
                  // defaultValue={Capture?.user}
                  onChange={(val) => {
                    if (totalselectedtheory !== 0 && val === false) {
                      openNotificationWithIcon(
                        "warning",
                        "To proceed with the theory questions, we need to enable the 'Capture User' option."
                      );
                    } else {
                      setIsCapturing({ ...Capture, user: val });
                    }

                    if (val === false) {
                      setIntroduction(false);
                    }
                  }}
                  checked={Capture?.user}
                />
              </div>
            </div>
            <div className={styles.settingBox}>
              {/* Copy Paste */}
              <div className={styles.lefttogglesetting}>
                <label>
                  Enable Copy/Paste ?{" "}
                  <Tooltip
                    placement="top"
                    title={() => (
                      <div style={{ width: "200px" }}>
                        Allowing this option will enable users to copy and paste
                        text during the test. It may be helpful for certain
                        tasks but could increase the risk of plagiarism or
                        unauthorized assistance.
                      </div>
                    )}
                  >
                    <InfoCircleOutlined
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        color: "#1890ff",
                      }}
                    />
                  </Tooltip>
                </label>
                <Switch
                  // defaultValue={Capture?.screen}
                  onChange={(val) =>
                    setSettings({ ...settings, copypaste: val })
                  }
                  checked={settings?.copypaste}
                />
              </div>
              {/* Auto Feedback */}
              <div className={styles.lefttogglesetting}>
                <label>
                  Auto Feedback{" "}
                  <Tooltip
                    placement="top"
                    title={() => (
                      <div style={{ width: "200px" }}>
                        Enabling this option will provide users with immediate
                        feedback on their answers after each question. This
                        helps them understand their performance in real-time and
                        learn from their mistakes.
                      </div>
                    )}
                  >
                    <InfoCircleOutlined
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        color: "#1890ff",
                      }}
                    />
                  </Tooltip>
                </label>
                <Switch
                  // defaultValue={Capture?.user}
                  onChange={(val) =>
                    setSettings({ ...settings, autofeedback: val })
                  }
                  checked={settings?.autofeedback}
                />
              </div>
            </div>

            <div className={styles.settingBox1}>
              {/* Close on Tab Change  */}

              {/* Practice Mode  */}
              <div className={styles.practicemode}>
                <div
                  className={styles.lefttogglesetting}
                  // style={{ width: "100%", marginTop: "15px" }}
                >
                  <label>
                    User Introduction{" "}
                    <Tooltip
                      placement="top"
                      title={() => (
                        <div style={{ width: "200px" }}>
                          This option allows users to introduce themselves
                          before starting the test. It can include their name,
                          background, or any relevant information, helping
                          create a more personalized testing environment.
                        </div>
                      )}
                    >
                      <InfoCircleOutlined
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          color: "#1890ff",
                        }}
                      />
                    </Tooltip>
                  </label>
                  <Switch
                    defaultValue={Introduction}
                    onChange={(e) => {
                      if (!Capture.user && e === true) {
                        openNotificationWithIcon(
                          "warning",
                          "To proceed with the introduction, you'll need to enable the 'Capture User' option."
                        );
                      } else {
                        setIntroduction(e);
                      }
                    }}
                    checked={Introduction}
                  />
                </div>
                <div className={`${styles.lefttogglesetting} mt-3`}>
                  <label>
                    Public{" "}
                    <Tooltip
                      placement="top"
                      title={() => (
                        <div style={{ width: "200px" }}>
                          Enabling this option will make your test publicly
                          accessible. Anyone with the link can take the test,
                          which may increase participation but could also lead
                          to unintended sharing or misuse of test materials.
                        </div>
                      )}
                    >
                      <InfoCircleOutlined
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          color: "#1890ff",
                        }}
                      />
                    </Tooltip>
                  </label>
                  <Switch
                    // defaultValue={Capture?.user}
                    onChange={(val) => {
                      if (val) {
                        setWarning(true);
                      } else {
                        setSettings({ ...settings, visible: false });
                      }
                    }}
                    checked={settings?.visible}
                  />
                </div>
              </div>

              {/* Test Timer */}

              {/* Visibility */}

              <Modal
                open={warning}
                onCancel={() => setWarning(false)}
                onOk={() => {
                  setSettings({ ...settings, visible: true });
                  setWarning(false);
                }}
                closable={false}
                maskClosable={false}
                okText="Yes"
                cancelText="No"
                style={{ width: "90%" }}
                title="Warning"
              >
                Test will be visible to everyone. Are you sure you want to make
                the test public?
              </Modal>
            </div>
            <div className={styles.settingBox} style={{ margin: "15px 0px" }}>
              <div className={styles.leftsettings1}>
                <label>
                  Close on Tab Change{" "}
                  <Tooltip
                    placement="top"
                    title={() => (
                      <div style={{ width: "200px" }}>
                        Enabling this option will automatically close or submit
                        the test if the user switches to a different tab or
                        window. This helps prevent distractions but may lead to
                        unintentional submissions if users navigate away
                        accidentally.
                      </div>
                    )}
                  >
                    <InfoCircleOutlined
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        color: "#1890ff",
                      }}
                    />
                  </Tooltip>
                </label>
                <div className={styles.settingBox}>
                  <Switch
                    defaultValue={closeontab?.close}
                    onChange={(val) => {
                      setCloseOnTab({ ...closeontab, close: val });
                    }}
                    checked={closeontab?.close}
                  />
                  <InputNumber
                    disabled={closeontab?.close === false}
                    value={closeontab?.limit}
                    onChange={(e) => setCloseOnTab({ ...closeontab, limit: e })}
                    className="w-50"
                    defaultValue={3}
                    max={10}
                    size="small"
                  />{" "}
                </div>
              </div>
              {(searchParams.size === 0 || location.state === null) && (
                <div className={styles.lefttogglesetting}>
                  <label>
                    Overall Timer{" "}
                    <Tooltip
                      placement="right"
                      title={() => (
                        <div style={{ width: "200px" }}>
                          This option allows you to set a total time limit for
                          the entire test. Once the timer reaches zero, the test
                          will automatically close, ensuring that users complete
                          the test within the allotted time.
                        </div>
                      )}
                    >
                      <InfoCircleOutlined
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          color: "#1890ff",
                        }}
                      />
                    </Tooltip>
                  </label>
                  <Switch
                    // defaultValue={Capture?.user}
                    onChange={(val) =>
                      setSettings({ ...settings, TestTimer: val })
                    }
                    checked={settings?.TestTimer}
                  />
                </div>
              )}
            </div>
            <div className={styles.settingBox}>
              {/* Screen Capture */}
              <div className={styles.lefttogglesetting}>
                <label>
                  Test Duration{" "}
                  <Tooltip
                    placement="right"
                    title={() => {
                      return (
                        <div style={{ width: "200px" }}>
                          * It's the minimum test duration, which may vary based
                          on random questions for individual users.
                          <br /> * However, the time allocated per specific
                          question remains consistent.
                        </div>
                      );
                    }}
                  >
                    <InfoCircleOutlined
                      style={{ cursor: "pointer", color: "#1890ff" }}
                    />
                  </Tooltip>
                </label>
                <label>
                  {settings?.TestTimer ? (
                    <InputNumber
                      value={Questionandduration.duration}
                      controls={false}
                      onChange={(val) =>
                        setQuestionandduration((prev) => ({
                          ...prev,
                          duration: val,
                        }))
                      }
                      addonAfter="Mins"
                      width={40}
                      size="small"
                      style={{ width: "100px" }}
                    />
                  ) : (
                    Questionandduration.duration + "/Mins"
                  )}
                </label>
              </div>
              {/* User Capture */}
              <div className={styles.lefttogglesetting}>
                <label>Total Questions</label>
                <label>{Questionandduration.totalquestion}</label>
              </div>
            </div>
            {(searchParams.size > 0 || location.state !== null) && (
              <div
                className={styles.lefttogglesetting}
                style={{ width: "45%" }}
              >
                <label>
                  Practice Mode{" "}
                  <Tooltip
                    title=" Enabling Practice Mode allows users to take the test in a non-graded format. Users can attempt questions without the pressure of time limits or scoring, making it a great way to learn and improve their skills.
    "
                  >
                    {" "}
                    <InfoCircleOutlined
                      style={{ cursor: "pointer", color: "#1890ff" }}
                    />
                  </Tooltip>
                </label>
                <Switch
                  defaultValue={Practicemode}
                  onChange={(e) => setPracticemode(e)}
                  checked={Practicemode}
                />
              </div>
            )}
            {(mockcat === "Offline Written" || mockcat === "OMR based MCQ") && (
              <div
                className={styles.lefttogglesetting}
                style={{ width: "100%" }}
              >
                <label>
                  No. of set
                  <Tooltip title="Turn on the switch to enter no. of test set you want to generate">
                    <InfoCircleOutlined
                      style={{
                        cursor: "pointer",
                        color: "#1890ff",
                        marginLeft: "10px",
                      }}
                    />
                  </Tooltip>
                </label>
                <Switch
                  defaultValue={testSet}
                  onChange={(e) => setTestSet(e)}
                  checked={testSet}
                />
                <div style={{ width: "45%" }}>
                  <Input
                    value={testSetCount}
                    onChange={(e) => setTestSetCount(e.target.value)}
                    disabled={!testSet}
                    addonAfter={"Max " + 4}
                    max={4}
                    placeholder="Enter no of set you want"
                  />
                </div>
              </div>
            )}
          </div>
          {(mockcat === "Offline Written" || mockcat === "OMR based MCQ") && (
            <div className={styles.pdfSec}>
              {examPaperQuestionSet.length > 0 &&
                examPaperQuestionSet.map((ele, index) => {
                  return (
                    <div key={index + 1}>
                      {ele.length === 0 ? (
                        <Tooltip title="There no more question to generate different set">
                          <Button>Invalid Set</Button>
                        </Tooltip>
                      ) : (
                        <>
                          {mockcat !== "OMR based MCQ" ? (
                            <>
                              <Tooltip title="Click to download">
                                <Button
                                  onClick={() => {
                                    generatePDF(
                                      ele,
                                      `${TestName} Set ${index + 1}`
                                    );
                                    downloadSvgQRCode(TestName, index + 1);
                                  }}
                                >
                                  Download
                                </Button>
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              <Tooltip title="Download Set PDf with OMR Sheet">
                                <Button
                                  onClick={() => {
                                    generatePDF(
                                      ele,
                                      `${TestName} Set ${index + 1}`
                                    );
                                    replaceHeader(
                                      TestName,
                                      index + 1,
                                      `https://unoprep.com/uploadomr/${user?.subdomain}/${Mockid}/${index}`
                                    );
                                    downloadSvgQRCode(TestName, index + 1);
                                  }}
                                >
                                  Test Set {index + 1}
                                </Button>
                              </Tooltip>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
              <Button
                style={{ width: "45%", marginRight: "15px" }}
                onClick={generateRandomQuestion}
              >
                {loading ? "Please wait..." : "Generate PDF"}
              </Button>
            </div>
          )}

          {mockcat === "Scholarship" && (
            <div
              style={{
                display: "flex",
                width: "40%",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Table
                style={{ display: "flex", justifyContent: "center" }}
                components={[]}
                rowClassName={() => "editable-row"}
                bordered
                pagination={false}
                dataSource={ScholarShipData}
                columns={[
                  {
                    title: "Test %",
                    dataIndex: "score",
                    editable: true,
                    render: (_, record) => {
                      return isEditingScholar(record) ? (
                        <Input
                          type="number"
                          value={record?.score}
                          onChange={(e) => {
                            const newData = [...ScholarShipData];
                            const index = newData?.findIndex(
                              (item) => record.key === item?.key
                            );
                            newData[index].score = e?.target?.value;
                            setScholarShipData(newData);
                          }}
                          onBlur={() => saveScholarShipData(record?.key)}
                        />
                      ) : (
                        <div onClick={() => editScholar(record?.key)}>
                          {record?.score}
                        </div>
                      );
                    },
                  },
                  {
                    title: "Scholarship %",
                    dataIndex: "offer",
                    editable: true,
                    render: (_, record) => {
                      return isEditingScholar(record) ? (
                        <Input
                          type="number"
                          value={record?.offer}
                          onChange={(e) => {
                            const newData = [...ScholarShipData];
                            const index = newData.findIndex(
                              (item) => record?.key === item?.key
                            );
                            newData[index].offer = e.target?.value;
                            setScholarShipData(newData);
                          }}
                          onBlur={() => saveScholarShipData(record?.key)}
                        />
                      ) : (
                        <div onClick={() => editScholar(record?.key)}>
                          {record?.offer}
                        </div>
                      );
                    },
                  },
                  {
                    title: "Action",
                    dataIndex: "delete",
                    render: (_, record) => {
                      return (
                        <div
                          onClick={() => {
                            setScholarShipData(
                              ScholarShipData?.filter(
                                (item) => item?.key !== record?.key
                              )
                            );
                          }}
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          {"Delete"}
                        </div>
                      );
                    },
                  },
                ]}
              />
              <Button
                disabled={ScholarShipData.length === 4}
                onClick={handleAddScholar}
              >
                Add Row
              </Button>
            </div>
          )}
        </div>
      ),
    },
  ];
  const items = stepData.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <>
      <div className={styles.mainBox}>
        <div className={styles.slider}>
          <DashboardSlider />
        </div>
        <div className={styles.main}>
          {/* Heading */}
          <LatestNavbar setOpenDwa={setOpenDwa} openDwa={openDwa} />
          <ToastContainer />
          {contextHolder}
          {contextHolder2}
          <Modal
            width={1100}
            open={openPreview}
            onOk={() => {
              setOpenPreview(false);
            }}
            closable={false}
            maskClosable={false}
            okText="Yes"
            title="Your filtered questions"
            footer={(_, { OkBtn }) => (
              <>
                <OkBtn />
              </>
            )}
          >
            <Table
              columns={columns}
              dataSource={QuestionToShow}
              // loading={loading}
              style={{
                width: "99%",
                margin: `5px 0px auto 0px`,
              }}
              caption={true}
              scroll={{
                y: "70vh",
              }}
              size="small"
              expandable={{
                expandedRowRender: (record) => (
                  <div className={styles.passage}>
                    {record?.type === "Passage" ? (
                      <div className={styles.passageInner}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: record.question, // Assuming `ele.question` contains the question content
                          }}
                        />
                        {record?.item?.qstn?.passageQuestions?.map(
                          (ele, index) => {
                            return (
                              <div
                                key={index}
                                className={styles.detailsPassage}
                              >
                                <label>Question : {index + 1}</label>

                                <label>{ele?.description}</label>
                                <h4>Options</h4>
                                <div>
                                  {ele?.options?.map((opt, index) => {
                                    return (
                                      <h5
                                        key={index}
                                        style={
                                          opt === ele.answer
                                            ? {
                                                color: "green",
                                              }
                                            : { color: "black" }
                                        }
                                      >
                                        {opt}
                                      </h5>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    ) : (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: record.question,
                        }}
                      />
                    )}
                  </div>
                ),
                rowExpandable: (record) => record.title ?? false,
              }}
            />
          </Modal>
          <div className={styles.heading}>
            <h2>Test Creation Screen</h2>
          </div>
          {Editloading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <Form form={form} layout="vertical" style={{ margin: "50px" }}>
              <Steps current={current} items={items} />
              <div style={contentStyle}>{stepData[current].content}</div>
              <div style={{ marginTop: 24 }}>
                {current < stepData.length - 1 && (
                  <Button type="primary" onClick={next}>
                    Next
                  </Button>
                )}
                {current === stepData.length - 1 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      if (
                        mockcat === "Offline Written" &&
                        examPaperQuestionSet.length === 0
                      ) {
                        message.warning("Please generate set frist then save");
                      } else if (
                        mockcat === "Scholarship" &&
                        ScholarShipData.length === 0
                      ) {
                        message.warning(
                          "Give some Scholarship details that you want to offer"
                        );
                      } else {
                        form
                          .validateFields()
                          .then((values) => {
                            Submit();
                          })
                          .catch((info) => {
                            console.log("Validate Failed:", info);
                          });
                      }
                    }}
                  >
                    {location?.state?.testtype === "CATupdate" && edit
                      ? `Update ${mockcat} Section`
                      : mockcat === "Offline Written"
                      ? `Save and Create Test`
                      : searchParams.get("add") && !edit
                      ? `Add to ${mockcat} Section`
                      : edit && !searchParams.get("add")
                      ? `Update Test`
                      : `Create Test`}
                  </Button>
                )}
                {current > 0 && (
                  <Button style={{ margin: "0 8px" }} onClick={prev}>
                    Previous
                  </Button>
                )}
                <HandleSubmit show={show} />
              </div>
            </Form>
          )}
        </div>
      </div>
      <ProfileSlider setOpenDwa={setOpenDwa} openDwa={openDwa} />
    </>
  );
};
export default CreateMock;
