import React, { useEffect, useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  setAdmin,
  setuser,
  setIssubscribed,
  setIslogin,
  setAccess,
  setIsSuperAdmin,
} from "../../store/Reducer";
import { updateProfilePhoto } from "../../store/StudentProfileSlice";
import { useDispatch } from "react-redux";
import { getrequest } from "../Middleware/managerequest";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
function Protected({ children, isAdmin }) {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token =
    process.env.REACT_APP_ENVIRONMENT === "local"
      ? Cookies.get("digikull_local")
      : Cookies.get("unoprep");

  const HandleRequest = useCallback(
    (token) => {
      const { Email, subdomain, isAdmin } = jwtDecode(token);
      let image = localStorage.getItem("my_profile_image");
      dispatch(updateProfilePhoto(image));
      let FullName = localStorage.getItem("FullName");

      const obj = {
        FullName: FullName,
        Email: Email,
        subdomain: subdomain,
      };
      dispatch(setuser(obj));
      dispatch(setAdmin(isAdmin));
      dispatch(setIslogin(true));
    },
    [dispatch]
  );

  const signout = useCallback(() => {
    let isUsers = false,
      isGroup = false,
      ClientName = "Digikull",
      isSubscribed = false,
      MockCount = 0;
    dispatch(setuser(null));
    dispatch(setAdmin(false));
    dispatch(setIslogin(false));
    dispatch(
      setIssubscribed({ isUsers, isGroup, ClientName, isSubscribed, MockCount })
    );
    dispatch(setIsSuperAdmin(null))
    sessionStorage.clear();
    localStorage.removeItem("FullName");
    localStorage.removeItem("my_profile_image");
    navigate("/sessionexpired");
    if (process.env.REACT_APP_ENVIRONMENT === "local") {
      Cookies.remove("digikull_local");
    } else {
      Cookies.remove("unoprep", { domain: ".unoprep.com" });
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (accounts.length > 0) {
      const msal_account = localStorage.getItem("msal.account.keys");
      if (!msal_account) {
        instance
          .acquireTokenSilent({
            account: accounts[0],
            scopes: process.env.SCOPE,
          })
          .then((res) => {
            if (!token) {
              getrequest(
                `/home/auth/singlesignin/?name=${accounts[0].name}&username=${accounts[0].username}`
              )
                .then((res) => {
                  if (res.status === 200) {
                    HandleRequest(res.data.token);
                    localStorage.setItem(
                      "my_profile_image",
                      res.data.imagesurl
                    );
                    dispatch(updateProfilePhoto(res.data.imagesurl));
                    if (process.env.REACT_APP_ENVIRONMENT === "local") {
                      Cookies.set("digikull_local", res.data.token, {
                        expires: 1,
                      });
                    }
                  }
                })
                .catch((err) => console.log(err));
            }
          });
      } else {
        if (!token) {
          getrequest(
            `/home/auth/singlesignin/?name=${accounts[0].name}&username=${accounts[0].username}`
          )
            .then((res) => {
              if (res.status === 200) {
                HandleRequest(res.data.token);
                localStorage.setItem("my_profile_image", res.data?.imagesurl);
                dispatch(updateProfilePhoto(res.data?.imagesurl));
                if (process.env.REACT_APP_ENVIRONMENT === "local") {
                  Cookies.set("digikull_local", res.data.token, { expires: 1 });
                }
              }
            })
            .catch((err) => console.log(err));
        }
      }
    }

    if (token) {
      HandleRequest(token);
    } else if (accounts.length === 0 && !token) {
      sessionStorage.clear();
    }
  }, [token, HandleRequest, instance, accounts, dispatch]);

  useEffect(() => {
    let token =
      process.env.REACT_APP_ENVIRONMENT === "local"
        ? Cookies.get("digikull_local")
        : Cookies.get("unoprep");

    if (token) {
      const { subdomain, isAdmin, Email, IsSuperAdmin } = jwtDecode(token);
      if (IsSuperAdmin) {
        dispatch(setIsSuperAdmin(IsSuperAdmin));
      }
      if (isAdmin === "true" || isAdmin === true || IsSuperAdmin) {
        getrequest(`/fetchuser/${subdomain}/?userid=${Email}`)
          .then((res) => {
            const { clientdetails, findaccess } = jwtDecode(res.data.token);

            if (clientdetails) {
              dispatch(setIssubscribed(clientdetails));
            }
            
            if (findaccess) {
              dispatch(setAccess(findaccess));
            }
          })
          .catch(() => <Navigate to="/sessionexpired" replace />);
      } else {
        getrequest(`/user/fetchmyowner/${subdomain}`)
          .then((res) => {
            dispatch(setIssubscribed(res.data.clientdetails));
          })
          .catch(() => <Navigate to="/sessionexpired" replace />);
      }
    } else if (!token && accounts.length === 0) {
      signout();
    }
  }, [children, signout, dispatch, accounts]);
  const secret = sessionStorage.getItem("secret");

  const isAdminSignedIn = isAdmin && secret !== "false";
  const isUserSignedIn = !isAdmin && !!token;

  if (isAdminSignedIn || isUserSignedIn) {
    return children;
  }

  return <Navigate to="/" replace />;
}

export default Protected;
