import styles from "./SignUp.module.css";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import officeComapnyLogo from "../../../icons/officeComapnyLogo.png";
import studentLogo from "../../../icons/studentLogo.png";
import JustDigikullLogo from "../../../icons/logounoprep.png";

const SignUp = () => {
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.leftSidebar}>
          <div className={styles.navbar}>
            <img src={JustDigikullLogo} alt="logo" />
          </div>

          <div className={styles.rightSidebarContext}>
            <label className={styles.Mock}>Tell us about yourself</label>
            <label className={styles.rTxt}>
              We will personalize your setup experience accordingly.
            </label>

            <div
              className={styles.hand}
              onClick={() => setSelected(2)}
              style={{ border: selected === 2 ? "2px solid black" : "" }}
            >
              <div>
                <img src={studentLogo} alt="User" />
              </div>
              <div className={styles.handTxt}>
                <label className={styles.label1}>
                  I am a student/candidate{" "}
                </label>
                <br />
                <p className={styles.label2}>Looking to take Tests</p>
              </div>
            </div>

            <div
              className={styles.horn}
              onClick={() => setSelected(1)}
              style={{ border: selected === 1 ? "2px solid black" : "" }}
            >
              <div>
                <img src={officeComapnyLogo} alt="Business" />
              </div>
              <div className={styles.hornTxt}>
                <label className={styles.label1}>
                  I am an organization/training institute
                </label>
                <br />
                <p className={styles.label2}>
                  Looking to launch Tests for my students
                </p>
              </div>
            </div>

            <div className={styles.btnDiv}>
              <button
                onClick={() => {
                  if (selected === 1) {
                    navigate("/organization", {
                      state: {
                        id: state?.id,
                        client_id: state?.client_id,
                        external: state?.external,
                      },
                    });
                  } else {
                    navigate("/personal", {
                      state: {
                        id: state?.id,
                        client_id: state?.client_id,
                        external: state?.external,
                      },
                    });
                  }
                }}
                disabled={selected === 0}
                className={styles.btn}
                style={{ background: selected === 0 ? "#D3D3D3" : "#08213E" }}
              >
                Create Account
              </button>

              <label
                className={styles.alreadyaccount}
                onClick={() => {
                  if (state?.id !== undefined) {
                    let encoded = state?.external
                      ? encodeURIComponent(
                          `id=${state.id}&client_id=${state.client_id}&ref=external`
                        )
                      : encodeURIComponent(
                          `/login/?id=${state.id}&client_id=${state.client_id}`
                        );
                    navigate(`/?${encoded}`, {
                      state: { id: state.id, client_id: state.client_id },
                    });
                  } else {
                    navigate("/");
                  }
                }}
              >
                I already have a Account.
              </label>
            </div>
          </div>
        </div>
        {/* Landing Carousel */}
        {/* {selected === 0 && (
          <div className={styles.rightSidebar}>
            <div className={styles.rightImage}>
             
            </div>

            <center>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "50%",
                  marginTop: "10px", // Adjust the width as needed
                }}
              >
                {data?.map((item, ind) => {
                  return (
                    <div
                      style={{
                        flex: 1,
                        height: "3px",
                        background: getColorForIndex(ind),
                      }}
                      key={ind}
                    ></div>
                  );
                })}
              </div>
            </center>

            <div className={styles.rightSidebarTxt}>
              <label className={styles.first}>{data[index].heading}</label>
              <br />
              <label className={styles.descriptiontext}>
                <small>{data[index].Description}</small>
              </label>
            </div>

            <div className={styles.Dot}>
              <div
                className={index === 0 ? styles.dot_sub1 : styles.dot_sub2}
              ></div>
              <div
                className={index === 1 ? styles.dot_sub1 : styles.dot_sub2}
              ></div>
              <div
                className={index === 2 ? styles.dot_sub1 : styles.dot_sub2}
              ></div>
            </div>
          </div>
        )}

        {selected === 1 && (
          <div className={styles.rightSidebarTrial}>
            <div className={styles.freeTrial}>
              <div className={styles.trial}>Free trial</div>
              <div className={styles.trailHr}>
                <hr />
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>Unlimited Mock Interviews</label>
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>Unlimited Assignemnt Attempts</label>
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>Premade Question Banks</label>
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>30+ skills to Test</label>
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>Group Creating Feature</label>
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>AI evaluation</label>
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>Transcribe Every Word</label>
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>Anti-Cheat Mechanism</label>
              </div>
            </div>
          </div>
        )}

        {selected === 2 && (
          <div className={styles.rightSidebarTrial}>
            <div className={styles.freeTrial}>
              <div className={styles.account}>Free account</div>
              <div className={styles.accountHr}>
                <hr />
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>Unlimited Mock Interviews</label>
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>3 Assignemnt Attempts</label>
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>Get Certified</label>
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>30+ skills to Test</label>
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>Group Creating Feature</label>
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>AI evaluation</label>
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>Transcribe Every Word</label>
              </div>
              <div>
                <img src={TickMark} alt="error" />
                <label>Anti-Cheat Mechanism</label>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};
export default SignUp;
