import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import jsPDF from 'jspdf';
import QRCode from 'qrcode';
import styles from "./UploadOMR.module.css";
import axios from "axios";
import { postrequest } from "../../Middleware/managerequest";
const UploadORM = () => {
  const videoRef = useRef(null); // Reference for the video element
  const canvasRef = useRef(null); // Reference for the canvas element
  const [photo, setPhoto] = useState(null); // State to store the captured photo
  const [selectedFile, setSelectedFile] = useState(null);

  // Function to start the webcam
  const startWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream; // Set the video source to the webcam stream
    } catch (err) {
      console.error("Error accessing the camera: ", err);
    }
  };

  // Function to capture the photo
  const capturePhoto = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height); // Draw video frame on the canvas
    const imageData = canvas.toDataURL("image/png"); // Get the image data as a base64 string
    setPhoto(imageData); // Set the captured photo to state
  };

  // Start the webcam when the component mounts
  useEffect(() => {
    startWebcam();
    return () => {
      const stream = videoRef.current?.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop()); // Stop all tracks when the component unmounts
      }
    };
  }, []);

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      // Send image to backend
      const response = await postrequest("/home/uploadomr", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response.data); // Handle response from backend
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  return (
    <div className={styles.container}>
      <h1>Upload you OMR Sheet</h1>
      <div className={styles.uploadGallery}>
        <h5>Upload from Gallery</h5>
        <input onChange={handleFileChange} type="file" />
      </div>
<Button onClick={handleUpload}>Done</Button>
      <h6>OR</h6>

      {/* <div className={styles.takePic}>
        <h6>Capchure from Camera</h6>
        <video
          ref={videoRef}
          autoPlay
          style={{ width: "300px", height: "auto" }}
        />
        <br />
        <Button onClick={capturePhoto}>{!photo?"Capture Photo":"Recapture Photo"}</Button>
        <br />
        <canvas
          ref={canvasRef}
          style={{ display: "none" }}
          width="300"
          height="200"
        />
        {photo && (
          <img
            src={photo}
            alt="Captured"
            style={{ width: "300px", height: "auto" }}
          />
        )}
      </div> */}
    </div>
  );
};
export default UploadORM;
