import React, { useState, useEffect, useCallback, useRef } from "react";
import { Table, Popconfirm, Space, Input, Select, Modal, Tag } from "antd";
import { CloseOutlined, RedoOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import styles from "./MockDetails.module.css";
import Excel from "exceljs";
import Spinner from "react-bootstrap/Spinner";
import { saveAs } from "file-saver";
import deleteUser from "../../../icons/deleteUser.svg";
import deleteUserData from "../../../icons/deleteUserData.svg";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { getrequest, postrequest } from "../../Middleware/managerequest";
import DashboardSlider from "../../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../../Navigator_Footer/ProfileSlider";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";

const AndDesign = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openDwa, setOpenDwa] = useState(false);
  const [Selecteduser, setSelectedUsers] = useState([]);
  const [data, setData] = useState();
  const [info, setInfo] = useState(false);
  const [Users, setUsers] = useState([]);
  const [testType, setTestType] = useState("");
  const [scholarShipFilter, setScholarShipFilter] = useState([]);

  const [show, setShow] = useState(false);
  const dataMock = useSelector((state) => state.Data);

  const { user, clientdetails, access, IsSuperAdmin } = dataMock;
  const [alluser, setallUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // handleClose
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    Getusers(item);
    setShow(true);
  };
  const { id } = useParams();
  const onSelectedRowKeysChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const mockupdate = () => {
    getrequest(`/particularmock/${user?.subdomain}/${id}`)
      .then((res) => {
        setData(res.data.mockdetails);
        let testTotal = 0;
        res.data.mockdetails.Attempted.map((item) => {
          const total = item.Answers?.reduce((accumulator, element) => {
            return accumulator + Number(element.mainpoints);
          }, 0);

          if (total > testTotal) {
            testTotal = total;
          }

          return true;
        });
        deStucture(res.data.mockdetails, testTotal, res.data.userdetails);
      })
      .catch((err) =>
        toast.warning("server error", {
          position: "top-right",
          autoClose: 1500,
        })
      );
  };
  // xvb
  const deStucture = useCallback((data, testtotal, m) => {
    let newData = [];
    setTestType(data?.type);
    setScholarShipFilter(data?.Extra?.Scholarship);

    data?.users?.forEach((item) => {
      // Total Points Obtained
      const Points = (val) => {
        const find = data.Attempted.find((user) => user.Email === val);
        let sum = 0;
        let total = 0;
        find?.Answers?.forEach((element) => {
          if (element?.Type === "Passage") {
            element?.answer?.forEach((ele, index) => {
              sum += Number(ele?.point);
            });
            element?.Question?.passageQuestions?.forEach((ele1, index) => {
              total += Number(ele1?.point);
            });
          } else {
            sum += Number(element.point);
            total += Number(element.mainpoints);
          }
        });
        if (sum <= 0 && total <= 0) {
          return "---";
        } else {
          return `${sum}/${testtotal}`;
        }
      };
      const Percentage = (val) => {
        const find = data.Attempted.find((user) => user.Email === val);
        let sum = 0;
        let total = 0;

        find?.Answers?.forEach((element) => {
          if (element?.Type === "Passage") {
            element?.answer?.forEach((ele) => {
              sum += Number(ele?.point);
            });
            element?.Question?.passageQuestions?.forEach((ele1) => {
              total += Number(ele1?.point);
            });
          } else {
            sum += Number(element.point);
            total += Number(element.mainpoints);
          }
        });

        if (sum <= 0 && total <= 0) {
          return "---";
        } else {
          const percentage = (sum / total) * 100;
          return `${percentage.toFixed(2)}`; // Limit to 2 decimal places
        }
      };

      //Result Status

      const Result = (val) => {
        const find = data.Attempted.find((user) => user.Email === val);
        let sum = 0,
          total = 0;

        find?.Answers?.forEach((element) => {
          if (element?.Type === "Passage") {
            element?.answer?.forEach((ele, index) => {
              sum += Number(ele?.point);
            });
            element?.Question?.passageQuestions?.forEach((ele1, index) => {
              total += Number(ele1?.point);
            });
          } else {
            sum += Number(element.point);
            total += Number(element.mainpoints);
          }
        });
        let percentage = (sum / total) * 100;
        if (percentage >= 60) {
          return "Pass";
        } else if (!find?.FeedBack) {
          return "---";
        } else {
          return "Failed";
        }
      };
      // Communication Status
      const Communication = (val) => {
        const find = data.Attempted.find((item) => item.Email === val);

        if (find?.FeedBack) {
          return find?.Level?.Communication;
        }
        if (!find?.FeedBack) {
          return "---";
        }
      };
      // Student Mock Status
      const Status = (val) => {
        const filter = data.Attempted.find(
          (item) => item.Email === val && item.FeedBack
        );
        const not = data.users.find(
          (item) => item.value === val && item.isSubmitted
        );
        if (filter) {
          return "Reviewed";
        } else if (not) {
          return "Attempted";
        } else if (!not) {
          return "Not Attempted";
        }
      };
      const isEligibleForScholarship = (createria, percentage) => {
        const numericPercentage = Number(percentage);

        if (isNaN(numericPercentage)) {
          return false;
        }
        const filted = createria?.filter(
          (scholarshipItem) =>
            numericPercentage >= Number(scholarshipItem?.score)
        );
        return filted.length > 0;
      };

      const numbers = (val) => {
        return m?.filter((it) => it.email === val)[0]?.Mobile;
      };

      const findname = (val) => {
        let flt = data.Attempted.find((item) => item.Email === val.value);
        if (flt) {
          let obj = {
            value: val.value,
            label: flt.FullName,
            isSubmitted: val.isSubmitted,
            name: val.name,
          };
          return obj;
        } else {
          return val;
        }
      };
      let obj = {
        // ...item,
        ...findname(item),
        point: Points(item.value),
        key: item.value,
        result: Result(item.value),
        Communication: Communication(item.value),
        Status: Status(item.value),
        Mobile: numbers(item.value),
        Percentage: `${Percentage(item?.value)}%`,
        ...(data?.type === "Scholarship" && {
          Scholarship: isEligibleForScholarship(
            data?.Extra?.Scholarship,
            Percentage(item?.value)
          )
            ? "Eligible"
            : "Not Eligible",
        }),
      };
      newData.push(obj);
    });
    setallUsers(newData);
  }, []);

  // Fetch The Data
  useEffect(() => {
    if (!data && user) {
      getrequest(`/particularmock/${user?.subdomain}/${id}`)
        .then((res) => {
          setData(res.data.mockdetails);
          let testTotal = 0;
          res.data.mockdetails.Attempted.forEach((item) => {
            let total = 0;
            let mtotal = item.Answers?.reduce((acc, element) => {
              if (element?.Type === "Passage") {
                element?.Question?.passageQuestions?.forEach(
                  (ele1) => (total += Number(ele1?.point))
                );
              } else {
                acc += Number(element.mainpoints);
              }
              return acc;
            }, 0);

            testTotal = Math.max(testTotal, total + mtotal);
          });

          deStucture(res.data.mockdetails, testTotal, res.data.userdetails);
        })
        .catch((err) => {
          toast.warning("server error", {
            position: "top-right",
            autoClose: 1500,
          });
          console.log(err);
        });
    }
  }, [id, deStucture, data, user]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
          width: "300px",
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div className="d-flex justify-content-end mt-1 mb-1 mx-2">
          <CloseOutlined
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={() => close()}
            title="Close"
          />
        </div>
        <Input
          ref={searchInput}
          placeholder={`Search by Name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 50,
            }}
            title="Search"
            variant="outline-primary"
          >
            <SearchOutlined />
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({
                closeDropdown: false,
              });
            }}
            size="small"
            style={{
              width: 50,
            }}
            title="Reset"
            variant="outline-primary"
          >
            <RedoOutlined />
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // Table Columns
  const columns = [
    {
      title: "Student name",
      dataIndex: "label",
      ...getColumnSearchProps("label"),
    },
    {
      title: "Mobile",
      dataIndex: "Mobile",
      key: "mobile",
    },
    {
      title: "Communication",
      dataIndex: "Communication",

      filters: [
        {
          text: "Below Average",
          value: "Below Average",
        },
        {
          text: "Average",
          value: "Average",
        },

        {
          text: "Good",
          value: "Good",
        },
        {
          text: "Excellent",
          value: "Excellent",
        },
      ],
      onFilter: (value, record) => record.Communication.indexOf(value) === 0,
    },
    {
      title: "Status",
      dataIndex: "Status",
      filters: [
        {
          text: "Reviewed",
          value: "Reviewed",
        },
        {
          text: "Attempted",
          value: "Attempted",
        },
        {
          text: "Not Attempted",
          value: "Not Attempted",
        },
      ],
      onFilter: (value, record) => record.Status.indexOf(value) === 0,
    },
    {
      title: testType === "Scholarship" ? "Percentage" : "Marks",
      dataIndex: testType === "Scholarship" ? "Percentage" : "point",
      ...(testType === "Scholarship" && {
        filters: scholarShipFilter.map((ele) => {
          return {
            text: ele.score,
            value: ele.score,
          };
        }),
      }),
      onFilter: (value, record) => record.Percentage >= value,
    },
    {
      title: "Result",
      dataIndex: testType === "Scholarship" ? "Scholarship" : "result",
      filters:
        testType === "Scholarship"
          ? [
              {
                text: "Eligible",
                value: "Eligible",
              },
              {
                text: "Not Eligible",
                value: "Not Eligible",
              },
            ]
          : [
              {
                text: "Pass",
                value: "Pass",
              },
              {
                text: "Fail",
                value: "Failed",
              },
            ],
      onFilter:
        testType === "Scholarship"
          ? (value, record) => record.Scholarship === value
          : (value, record) => record.result.indexOf(value) === 0,
    },
  ];

  // Add Adduser
  const Addusers = (item) => {
    if (Selecteduser.length > clientdetails?.mockcount) {
      Warning();
    } else {
      let obj = {
        Selecteduser,
        MockData: item,
      };
      postrequest(`/deleteuser/${id}/${user?.subdomain}`, obj)
        .then((res) => {
          mockupdate();
          handleClose();
          Getusers(item);
          setSelectedUsers([]);
          setSelectedRowKeys([]);
          toast.success("User added successfully.", {
            position: "top-center",
            autoClose: "1000",
          });
        })
        .catch((err) =>
          toast.warning("server error", {
            position: "top-right",
            autoClose: 1500,
          })
        );
    }
  };
  // Check Passed Students
  const checkPassedStd = (data) => {
    let sum = 0,
      total = 0;

    let passed = data.Attempted.filter((user) => {
      sum = 0; // Initialize sum for each user
      total = 0; // Initialize total for each user

      // Filter the user's answers
      user.Answers?.map((element) => {
        if (element?.Type === "Passage") {
          element?.answer?.forEach((ele) => {
            sum += Number(ele?.point);
          });
          element?.Question?.passageQuestions?.forEach((ele1) => {
            total += Number(ele1?.point);
          });
        } else {
          sum += Number(element.point);
          total += Number(element.mainpoints);
          return true;
        }
      });
      let percentage = (sum / total) * 100;
      // Return true to include the answer if percentage is greater than or equal to 65
      return percentage >= 60;
      // Return true to include the user if there are filtered answers
    });
    return passed.length;
  };

  const Deleteuser = (e) => {
    if (e) {
      let obj = {
        selectedRowKeys,
      };
      postrequest(`/deleteuser/${id}/${user?.subdomain}`, obj)
        .then((res) => {
          mockupdate();
          setSelectedRowKeys([]);
        })
        .catch((err) =>
          toast.warning("server error", {
            position: "top-right",
            autoClose: 1500,
          })
        );
    }
  };

  // Sidebar Navigator
  const handlerClick = (e) => {
    if (e === "Download Excel Sheet") {
      ExporttoExcel(data);
    } else if (e === "Add New User") {
      Getusers(data);
      handleShow();
    } else if (e === "Share Link") {
      shareTheLink(data);
    } else if (e === "More Info") {
      setInfo(true);
    }
    setOpenDwa(false);
  };

  const Warning = () => {
    toast.warning(`You can add upto ${clientdetails?.mockcount} users`, {
      position: "top-right",
      autoClose: 1500,
      closeOnClick: true,
    });
  };

  // Navigate to Review Page
  const review = (value) => {
    let find = data.Attempted.find((item) => item?.Email === value?.value);
    let ind = data.Attempted.findIndex((item) => item?.Email === value?.value);
    let allattempted = [];
    data.Attempted.forEach((item) => {
      let obj = {
        FullName: item.FullName,
        email: item.Email,
      };
      allattempted.push(obj);
    });
    navigate(`/review_mock/${id}`, {
      state: {
        ind,
        MockTitle: data.MockTitle,
        user: find,
        allattempted,
      },
    });
  };
  // Reset user Test
  const DeleteuserData = (e) => {
    if (e) {
      let obj = {
        selectedRowKeys,
      };
      postrequest(`/deleteuserdata/${id}/${user?.subdomain}`, obj)
        .then((res) => {
          mockupdate();
          setSelectedRowKeys([]);
        })
        .catch((err) =>
          toast.warning("server error", {
            position: "top-right",
            autoClose: 1500,
          })
        );
    }
  };
  const handleuser = (e) => {
    let find = Users.filter((val) => {
      return e.includes(val.value);
    });

    e.forEach((val) => {
      if (!Users.some((user) => user.value === val)) {
        let obj = {
          label: val.trim(),
          value: val.trim(),
          name: "users",
        };
        find.push(obj);
      }
    });
    setSelectedUsers(find);
  };

  //Fetch All users
  const Getusers = (item) => {
    getrequest(`/getusers/${id}/${user?.subdomain}`)
      .then((res) => {
        let arr = [];
        res.data.length > 0 &&
          res.data.map((item) => {
            let obj = {
              value: item.Email,
              label: item.Name,
              name: item.name === undefined ? "users" : item.name,
            };
            arr.push(obj);
            return true;
          });
        setUsers(arr);
      })
      .catch((err) =>
        toast.warning("server error", {
          position: "top-right",
          autoClose: 1500,
        })
      );
  };
  const scroll = {};
  if (true) {
    scroll.y = "63vh";
  }

  const tableColumns = columns.map((item) => ({
    ...item,
    hidden: access && access?.role !== "Admin" && item.key === "mobile",
  }));

  const tableProps = {
    scroll,
    rowSelectedBg: "green",
  };

  const convertDateIntoNormal = (inputDateString) => {
    // Create a Date object from the input string
    const date = new Date(inputDateString);

    // Define months array for formatting
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the day, month, and year components
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Format the date as "DD Mon YYYY"
    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  };

  // Export to Excel
  const ExporttoExcel = async (item) => {
    try {
      let arr = [];
      data.users.map((val) => {
        let filtereddata = data.Attempted.filter(
          (it) => it.Email === val.value
        );
        let sum = 0,
          total = 0;
        filtereddata.length > 0 &&
          filtereddata[0].Answers.forEach((element) => {
            sum += Number(element.point);
            total += Number(element.mainpoints);
          });

        if (filtereddata.length > 0) {
          let obj = {
            FullName: filtereddata[0].FullName,
            Email: filtereddata[0].Email,
            Status: "Present",
            Startdate: new Date(filtereddata[0].starttime),
            FeedBackStatus: !filtereddata[0].FeedBack
              ? "Reviewing"
              : "Returned",
            TotalMark: sum,
            GrandTotal: total,
            Percentage: Math.floor((sum / total) * 100),
            Result: filtereddata[0].FeedBack
              ? (sum / total) * 100 > 60
                ? "Pass"
                : "Fail"
              : "",
            FeedBack: filtereddata[0].FeedBack
              ? filtereddata[0].OverAllFeedback
              : "",
            Reviewer: filtereddata[0].FeedBack
              ? filtereddata[0].FeedBackBy
              : "",
            Communication: filtereddata[0].Level
              ? filtereddata[0].Level.Communication
              : "",
            Theory: filtereddata[0].Level ? filtereddata[0].Level.Theory : "",
            Coding: filtereddata[0].Level ? filtereddata[0].Level.Coding : "",
          };
          arr.push(obj);
        } else {
          let obj = {
            FullName: val.label,
            Email: val.value,
            Status: "Absent",
            Startdate: "",
            FeedBackStatus: "",
            TotalMark: 0,
            GrandTotal: 0,
            Percentage: 0,
            Result: "Fail",
            FeedBack: "",
            Reviewer: "",
          };
          arr.push(obj);
        }
        return true;
      });
      const columns = [
        { header: "FullName", key: "FullName" },
        { header: "Email", key: "Email" },
        { header: "Status", key: "Status" },
        { header: "Startdate", key: "Startdate" },
        { header: "FeedBack", key: "FeedBackStatus" },
        { header: "Total", key: "GrandTotal" },
        { header: "ObtainMark", key: "TotalMark" },
        { header: "Percentage", key: "Percentage" },
        { header: "Communication", key: "Communication" },
        { header: "Theory", key: "Theory" },
        { header: "Coding", key: "Coding" },
        { header: "Result", key: "Result" },
        { header: "OverAllFeedBack", key: "FeedBack" },
        { header: "FeedBackBy", key: "Reviewer" },
      ];
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet(item.MockTitle);
      worksheet.columns = columns;
      worksheet.getRow(1).font = { bold: true };
      worksheet.columns.forEach((column) => {
        column.width =
          column.header !== "Email"
            ? column.header.length + 10
            : column.header.length + 20;
        column.alignment = { horizontal: "center" };
      });
      arr.forEach((singleData) => {
        worksheet.addRow(singleData);
      });
      worksheet.eachRow({ includeEmpty: true }, (row) => {
        // Iterate over each cell in the row
        row.eachCell((cell) => {
          const cellValue = cell.value;

          if (cellValue === "Bad") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFF0000" }, // Red color
            };
            cell.font = {
              color: { argb: "FFFFFFFF" }, // Red color
              bold: true,
            };
          } else if (cellValue === "Ok") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "00E8D94F" },
            };
            cell.font = {
              color: { argb: "FFFFFFFF" },
              bold: true,
            };
          } else if (cellValue === "Good") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "00A9ED67" },
            };
            cell.font = {
              bold: true,
            };
          } else if (cellValue === "Excellent") {
            cell.font = {
              color: { argb: "FFFFFFFF" },
              bold: true,
            };
            cell.fill = {
              color: "FFFFFF",
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "002F732F" },
            };
          }
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });

      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${item.MockTitle}.xlsx`);
    } catch (err) {
      console.log(err.message);
    }
  };

  //Copy The Link
  const shareTheLink = (val) => {
    let url = encodeURIComponent(
      `id=${val.Mockid}&client_id=${
        clientdetails?.isusers ? user?.subdomain : "main"
      }&ref=external`
    );

    navigator.clipboard
      .writeText(`https://unoprep.com/?${url}`)
      .then(() => {
        toast.success("Copied the Link", {
          position: "top-center",
          autoClose: 1000,
        });
      })
      .catch((error) => {
        toast.error("Error while copying", {
          position: "top-center",
          autoClose: 1000,
        });
      });
  };

  // Cancel Remove
  const CancelRemove = () => {
    setSelectedRowKeys([]);
  };

  return (
    <>
      {/* Extra Component */}
      <ToastContainer />
      <div className={styles.and}>
        <div className={styles.main}>
          <div className={styles.mockDetails}>
            {/* Sidebar */}
            <DashboardSlider />
          </div>
        </div>
        {!data ? (
          <div
            style={{
              height: "100vh",
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner
              animation="border"
              size="lg"
              variant="warning"
              className="spinner"
            />
          </div>
        ) : (
          <div className={styles.tableBox}>
            <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />
            <div className={styles.navigation}>
              <div>
                <label
                  className={styles.homebutton}
                  onClick={() => navigate("/admin")}
                >
                  Home &gt;
                </label>
                <label className={styles.mocklabel}>{data?.MockTitle}</label>
              </div>

              <div className={styles.deleteBtns}>
                <button onClick={() => handlerClick("Add New User")}>
                  Add New User
                </button>
                <button
                  disabled={access?.role !== "Admin"}
                  style={
                    IsSuperAdmin || access?.role === "Admin"
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed" }
                  }
                  onClick={() => handlerClick("Download Excel Sheet")}
                >
                  Download Sheet
                </button>
                <button onClick={() => handlerClick("Share Link")}>
                  Copy Link
                </button>
                <button onClick={() => handlerClick("More Info")}>
                  More Info
                </button>
                {selectedRowKeys.length > 0 && (
                  <>
                    {" "}
                    <Popconfirm
                      title="Delete the User"
                      description="Are you sure to delete the users?"
                      onConfirm={Deleteuser}
                      onCancel={CancelRemove}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button>
                        <img src={deleteUser} alt="deleteuser" />
                        Remove User
                      </button>
                    </Popconfirm>
                    <Popconfirm
                      title="Reset the mock"
                      description="Are you sure to reset the mock of selected users Data?"
                      onConfirm={DeleteuserData}
                      onCancel={CancelRemove}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button>
                        <img src={deleteUserData} alt="deleteuser" />
                        Reset
                      </button>
                    </Popconfirm>
                  </>
                )}
              </div>
            </div>
            {/* Mockdetails Table */}
            <Table
              style={{
                marginBottom: "16px",
              }}
              {...tableProps}
              rowSelection={rowSelection}
              columns={tableColumns}
              dataSource={alluser}
              onRow={(record, rowIndex) => {
                return {
                  onClick: record.isSubmitted ? () => review(record) : null, // click row
                };
              }}
            />
          </div>
        )}

        <ProfileSlider setOpenDwa={setOpenDwa} openDwa={openDwa} />
        <Modal
          onOk={() => Addusers(data)}
          onCancel={handleClose}
          open={show}
          title="Add Users"
        >
          <Select
            mode="tags"
            style={{
              width: "100%",
              zIndex: "999",
            }}
            onChange={handleuser}
            tokenSeparators={[","]}
            options={Users}
            placeholder="Enter the email with ',' separated"
            value={Selecteduser}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
          <label>
            {" "}
            Press{" "}
            <Tag
              style={{
                backgroundColor: "rgba(150,150,150,0.06)",
                fontWeight: "600",
              }}
            >
              ,
            </Tag>
            or{" "}
            <Tag
              style={{
                backgroundColor: "rgba(150,150,150,0.06)",
                fontWeight: "600",
              }}
            >
              Tab
            </Tag>
            or{" "}
            <Tag
              style={{
                backgroundColor: "rgba(150,150,150,0.06)",
                fontWeight: "600",
              }}
            >
              Enter
            </Tag>{" "}
            to separated.
          </label>
        </Modal>
        <Modal
          onOk={() => setInfo()}
          onCancel={() => setInfo(false)}
          open={info}
          title="Test Information"
        >
          <div className={styles.testDetails}>
            <label>Test Name: {data?.MockTitle}</label>
            <label>
              Start Date:{" "}
              <span>{data && convertDateIntoNormal(data?.MockStart)}</span>
            </label>
            {data?.Mockend === " " ? (
              ""
            ) : (
              <label>
                End Date :{" "}
                <span>{data && convertDateIntoNormal(data?.Mockend)}</span>
              </label>
            )}

            <label>
              Duration : <span>{data?.Duration} Min</span>
            </label>
            <label>
              Total Invites : <span>{data?.users?.length}</span>
            </label>
            <label>
              Passed : <span>{data && checkPassedStd(data)}</span>
            </label>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default AndDesign;
