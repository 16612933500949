import React, { useCallback, useEffect, useState } from "react";

import "../../styles/manageclients.css";
import Spinner from "react-bootstrap/esm/Spinner";
import Form from "react-bootstrap/Form";
import { AiFillSetting } from "react-icons/ai";
import { getrequest, putrequest } from "../Middleware/managerequest";
import { useSelector } from "react-redux";
import { message, Select } from "antd";

const Manageorganization = () => {
  const [clientdata, setClientData] = useState(null);

  const { user } = useSelector((state) => state.Data);
  const [messageApi, contextHolder] = message.useMessage();
  const fetcher = useCallback(() => {
    getrequest(`/manageclients/${user?.subdomain}`)
      .then((res) => setClientData(res.data))
      .catch((err) => console.log(err));
  }, [user]);

  useEffect(() => {
    if (user) {
      fetcher();
    }
  }, [fetcher, user]);

  const SetClassName = (val) => {
    if (val === "Free") return "freeaccount accounttype";
    else return "paidaccount accounttype";
  };

  if (clientdata === null) {
    return (
      <div className="d-flex justify-content-center align-items-center h-50">
        <Spinner
          animation="border"
          size="lg"
          variant="warning"
          className="spinner"
        />
      </div>
    );
  }

  if (clientdata !== null && clientdata.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center h-50">
        <h3>No Organizations Registered</h3>
      </div>
    );
  }
  const successPopup = (msg) => {
    messageApi
      .open({
        type: "loading",
        content: "Action in progress..",
        duration: 1.8,
      })
      .then(() => message.success(msg));
  };

  const changesubscription = (item, val) => {
    successPopup("Account Activated");
    putrequest(`/changesubsciptions/${item.ClientId}/${val}`, "hello")
      .then(() => {
        fetcher();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const UPSCtopics = [
    { label: "Current affairs", value: "Current affairs" },
    { label: "History of India", value: "History of India" },
    { label: "India & World Geography", value: "India & World Geography" },
    {
      label: "Indian Polity and Governance",
      value: "Indian Polity and Governance",
    },
    {
      label: "Economic & Social development",
      value: "Economic & Social development",
    },
    { label: "General Science", value: "General Science" },
    {
      label: "General issues of environment",
      value: "General issues of environment",
    },
    { label: "Comprehension", value: "Comprehension" },
    { label: "Communication", value: "Communication" },
    { label: "Aptitude", value: "Aptitude" },
    {
      label: "Logical reasoning & analytical ability",
      value: "Logical reasoning & analytical ability",
    },
    {
      label: "Decision making & problem solving",
      value: "Decision making & problem solving",
    },
    { label: "General mental ability", value: "General mental ability" },
    { label: "Basic numeracy", value: "Basic numeracy" },
    { label: "Data interpretation", value: "Data interpretation" },
  ];

  const TechSkills = [
    { label: "HTML", value: "HTML" },
    { label: "CSS", value: "CSS" },
    { label: "Javascript", value: "Javascript" },
    { label: "React", value: "React" },
    { label: "DSA", value: "DSA" },
    { label: "Node", value: "Node" },
    { label: "Python", value: "Python" },
    { label: "React-Native", value: "React-Native" },
    { label: "Communication", value: "Communication" },
    { label: "Java", value: "Java" },
    { label: "Aptitude", value: "Aptitude" },
    { value: "MySQL", label: "MySQL" },
    { value: "PowerBI", label: "PowerBI" },
    { value: "Tableau", label: "Tableau" },
    { label: "Machine Learning", value: "Machine Learning" },
    { label: "Excel", value: "Excel" },
  ];

  const changeMainTopics = (val, item) => {
    successPopup("MainTopics updated successfully");
    putrequest(
      `/changesubject/${item?.SubDomain}`,
      val === "UPSC Preparation" ? UPSCtopics : TechSkills
    )
      .then((res) => {})
      .catch((err) => {
        message.warning("Something went wrong. Try again later");
      });
  };
  return (
    <div className="mt-5 pt-5">
      {contextHolder}
      <div className="d-flex flex-column  align-items-center">
        <div className="d-flex justify-content-start" style={{ width: "80%" }}>
          <h3 className="mx-5">Manage Organizations</h3>
        </div>
        <div className="manage_main">
          {clientdata &&
            clientdata.map((item, index) => {
              return (
                <div className="manage_sub" key={index}>
                  <div className="manage_accounts mx-3 mt-2">
                    <label className={SetClassName(item.PlanType)}>
                      {item.PlanType}
                    </label>
                    <Form.Check
                      reverse
                      type="switch"
                      id="custom-switch"
                      label="Active"
                      checked={item.isSubscribed}
                      value={item.isSubscribed}
                      style={{ cursor: "pointer" }}
                      onChange={(e) => changesubscription(item, e.target.value)}
                    ></Form.Check>
                  </div>
                  <div className="d-flex justify-content-start mx-3 align-items-center mt-2">
                    <img src={item?.logo} alt="clientlogo" height={50} />
                    <h3 className="mx-2">{item.ClientName}</h3>
                  </div>

                  <div className="d-flex justify-content-end">
                    <label
                      style={{ fontSize: "30px", cursor: "pointer" }}
                      className="mx-2"
                    >
                      <AiFillSetting title="Manage Account" />
                    </label>
                  </div>
                  <div className="subjectlist">
                    <h6>Subject list</h6>
                    <Select
                      onChange={(val) => {
                        changeMainTopics(val, item);
                      }}
                      placeholder="Select Category"
                      options={[
                        { label: "Tech Training", value: "Tech Training" },
                        {
                          label: "UPSC Preparation",
                          value: "UPSC Preparation",
                        },
                      ]}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Manageorganization;
